import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Board, Card, List } from 'app/modules/admin/apps/sites/sites.models';
import { ActualType, BudgetType, CompanyType, DrillRigType, InstrumentType, RockCode, SiteConfigType, SiteType, StructureDescriptionType } from 'app/modules/admin/forms/forms.types';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, map, of, switchMap, take, tap, throwError } from 'rxjs';
import { AllSitesGraphData } from './sites.types';

@Injectable({
	providedIn: 'root'
})
export class SitesService {

	apiUrl = environment.url;

	// Private
	private _board: BehaviorSubject<Board | null> = new BehaviorSubject(null);
	private _boards: BehaviorSubject<Board[] | null> = new BehaviorSubject(null);
	private _card: BehaviorSubject<Card | null> = new BehaviorSubject(null);
	private _sites: BehaviorSubject<SiteType[] | null> = new BehaviorSubject(null);
	private _site: BehaviorSubject<SiteType | null> = new BehaviorSubject(null);
	private _subsites: BehaviorSubject<any | null> = new BehaviorSubject(null);
	private _company: BehaviorSubject<CompanyType | null> = new BehaviorSubject(null);
	private _companies: BehaviorSubject<CompanyType[] | null> = new BehaviorSubject(null);
	private _drillrig: BehaviorSubject<DrillRigType | null> = new BehaviorSubject(null);
	private _drillrigs: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
	private _instruments: BehaviorSubject<InstrumentType[] | null> = new BehaviorSubject(null);
	private _rockCodebysiteid: BehaviorSubject<RockCode[] | null> = new BehaviorSubject(null);
	private _drillrigsbysiteid: BehaviorSubject<DrillRigType[] | null> = new BehaviorSubject(null);
	private _structuresbysiteid: BehaviorSubject<StructureDescriptionType[] | null> = new BehaviorSubject(null);
	private budgetsbysite: BehaviorSubject<BudgetType[] | null> = new BehaviorSubject(null);
	private actualsbysite: BehaviorSubject<ActualType[] | null> = new BehaviorSubject(null);
	private _siteconfigsbysiteid: BehaviorSubject<SiteConfigType | null> = new BehaviorSubject(null);
	private _formdata: BehaviorSubject<any | null> = new BehaviorSubject(null);

	/**
	 * Constructor
	 */
	constructor(
		private _httpClient: HttpClient,
		private location: Location
	) {
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * [deprecado] Getter for board
	 *
	 * @deprecated
	 */
	get board$(): Observable<Board> {
		return this._board.asObservable();
	}

	/**
	 * [deprecado] Getter for boards
	 *
	 * @deprecated
	 */
	get boards$(): Observable<Board[]> {
		return this._boards.asObservable();
	}

	/**
	 * [deprecado] Getter for card
	 *
	 * @deprecated
	 */
	get card$(): Observable<Card> {
		return this._card.asObservable();
	}

	/**
	 * Getter de Sites
	 *
	 * Um acessor de um conjuto de todos os Sites
	 *
	 * @readonly
	 * @type {Observable<SiteType[]>}
	 * @memberof SitesService
	 */
	get sites$(): Observable<SiteType[]> {
		return this._sites.asObservable();
	}

	/**
	 * Getter de Site
	 *
	 * Um acessor de site individual
	 *
	 * @readonly
	 * @type {Observable<SiteType>}
	 * @memberof SitesService
	 */
	get site$(): Observable<SiteType> {
		return this._site.asObservable();
	}

	/**
	 * Getter de Site
	 *
	 * Um acessor de site individual
	 *
	 * @readonly
	 * @type {Observable<SiteType>}
	 * @memberof SitesService
	 */
	get drillrig$(): Observable<DrillRigType> {
		return this._drillrig.asObservable();
	}

	/**
	 * Getter de Site
	 *
	 * Um acessor de site individual
	 *
	 * @readonly
	 * @type {Observable<SiteType>}
	 * @memberof SitesService
	 */
	get instrumentsbysiteid$(): Observable<InstrumentType[]> {
		return this._instruments.asObservable();
	}


	/**
	 * Getter de Site
	 *
	 * Um acessor de site individual
	 *
	 * @readonly
	 * @type {Observable<SiteType>}
	 * @memberof SitesService
	 */
	get drillrigs$(): Observable<any[]> {
		return this._drillrigs.asObservable();
	}

	/**
	 * Getter de Rockcode by site
	 *
	 * Um acessor de Rockcode individual
	 *
	 * @readonly
	 * @type {Observable<SiteType>}
	 * @memberof SitesService
	 */
	get rockCodebysiteid$(): Observable<RockCode[]> {
		return this._rockCodebysiteid.asObservable();
	}


	/**
	 * Getter de Drillrig by site
	 *
	 * Um acessor de Drillrig individual
	 *
	 * @readonly
	 * @type {Observable<SiteType>}
	 * @memberof SitesService
	 */
	get drillrigsbysiteid$(): Observable<DrillRigType[]> {
		return this._drillrigsbysiteid.asObservable();
	}
	/**
	 * Getter de structures by site id
	 *
	 * Um acessor de structures by site id
	 *
	 * @readonly
	 * @type {Observable<SiteType>}
	 * @memberof SitesService
	 */
	get structuresbysiteid$(): Observable<StructureDescriptionType[]> {
		return this._structuresbysiteid.asObservable();
	}
	/**
	 * Getter de budgets by site id
	 *
	 * Um acessor de budgets by site id
	 *
	 * @readonly
	 * @type {Observable<BudgetType>}
	 * @memberof SitesService
	 */
	get budgetsbysite$(): Observable<BudgetType[]> {
		return this.budgetsbysite.asObservable();
	}
	/**
	 * Getter de actual by site id
	 *
	 * Um acessor de actual by site id
	 *
	 * @readonly
	 * @type {Observable<ActualType>}
	 * @memberof SitesService
	 */
	get actualsbysite$(): Observable<ActualType[]> {
		return this.actualsbysite.asObservable();
	}
	/**
	 * Getter de Compnaies
	 *
	 * Um acessor de muitos companies
	 *
	 * @readonly
	 * @type {Observable<SiteType>}
	 * @memberof SitesService
	 */
	get companies$(): Observable<CompanyType[]> {
		return this._companies.asObservable();
	}
	/**
	 * Getter de Site
	 *
	 * Um acessor de site individual
	 *
	 * @readonly
	 * @type {Observable<SiteType>}
	 * @memberof SitesService
	 */
	get company$(): Observable<CompanyType> {
		return this._company.asObservable();
	}

	/**openfas
	 * Getter de Subsites
	 *
	 * Um acessor de um conjuto de subsites dentro de um Site
	 *
	 * @readonly
	 * @type {Observable<any>}
	 * @memberof SitesService
	 */
	get subsites$(): Observable<any> {
		return this._subsites.asObservable();
	}

	/**openfas
	 * Getter de Configurações do Site
	 *
	 * Um acessor de um conjuto de configurações dentro de um Site
	 *
	 * @readonly
	 * @type {Observable<any>}
	 * @memberof SitesService
	 */
	get siteconfigsbysiteid$(): Observable<SiteConfigType> {
		return this._siteconfigsbysiteid.asObservable();
	}

	/**openfas
	 * Getter de Configurações do Site
	 *
	 * Um acessor de um conjuto de configurações dentro de um Site
	 *
	 * @readonly
	 * @type {Observable<any>}
	 * @memberof SitesService
	 */
	get formdata$(): Observable<any> {
		return this._formdata.asObservable();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Novos metodos
	// -----------------------------------------------------------------------------------------------------

	/**
	 * ### Get Sites
	 *
	 * Busca todos os sites cadastrados no banco
	 *
	 * @return um array contendo todas as entidades de site do banco
	 * @memberof SitesService
	 */
	getSites(): Observable<SiteType[]> {
		return this._httpClient.get<SiteType[]>(this.apiUrl + 'site/all').pipe(
			tap((response: any) => {
				this._sites.next(response);
			})
		);
	}

	/**
	 * ### Get Site By ID
	 *
	 * Busca um site a partir de uma ID informada
	 *
	 * @param id a ID que o banco deve buscar
	 * @return uma entidade de Site, quando encontrada no banco
	 * @memberof SitesService
	 */
	getSiteById(id: string): Observable<SiteType> {
		return this._httpClient.get<SiteType>(this.apiUrl + 'site/' + id).pipe(
			tap((response: any) => {
				this._site.next(response);
			})
		);
	}

	/**
	 * ### Get Site By ID
	 *
	 * Busca um site a partir de uma ID informada
	 *
	 * @param id a ID que o banco deve buscar
	 * @return uma entidade de Site, quando encontrada no banco
	 * @memberof SitesService
	 */
	getSubsitesBySiteID(id: string): Observable<any> {
		return this._httpClient.get<any>(this.apiUrl + 'site/' + id + '/subSites').pipe(
			tap((response: any) => {
				this._subsites.next(response);
			})
		);
	}

	/**
	 * ### Get All Companies
	 *
	 * Busca todos os companies cadastrados no banco
	 *
	 * @return um array contendo todas as entidades de Company do banco
	 * @memberof SitesService
	 */
	getAllCompanies(): Observable<CompanyType[]> {
		return this._httpClient.get<CompanyType[]>(this.apiUrl + 'company/all').pipe(
			tap((response: CompanyType[]) => {
				this._companies.next(response);
			})
		);
	}

	/**
	 * ### Get Company By ID
	 *
	 * Busca um company a partir de uma ID informada
	 *
	 * @param id a ID da entidade a ser buscada
	 * @return uma entidade de Company, quando encontrada no banco
	 * @memberof SitesService
	 */
	getCompanyByID(id: string): Observable<CompanyType> {
		return this._httpClient.get<CompanyType>(this.apiUrl + 'company/' + id).pipe(
			tap((response: CompanyType) => {
				this._company.next(response);
			})
		);
	}

	/**
	 * ### Get Company By name query
	 *
	 * Busca um company a partir de uma ID informada
	 *
	 * @param name o nome  a ser buscada
	 * @return uma entidade de Company, quando encontrada no banco
	 * @memberof SitesService
	 */
	getCompanyBySiteNameContains(siteid: string): Observable<CompanyType> {
		return this._httpClient.get<CompanyType>(this.apiUrl + 'company?sitename=' + siteid).pipe(
			tap((response: CompanyType) => {
				this._company.next(response);
			})
		);
	}

	/**
	 * ### Get All Drill Rigs
	 *
	 * Busca todos os Drill Rigs cadastrados no banco
	 *
	 * @return um array contendo todas as entidades de Drill Rigs do banco
	 * @memberof SitesService
	 */
	getAllDrillRigs(): Observable<any[]> {
		return this._httpClient.get<any[]>(this.apiUrl + 'drill-rig/all').pipe(
			tap((response: any[]) => {
				this._drillrigs.next(response);
			})
		);
	}

	/**
	 * ### Get Company By ID
	 *
	 * Busca um company a partir de uma ID informada
	 *
	 * @param id a ID da entidade a ser buscada
	 * @return uma entidade de Company, quando encontrada no banco
	 * @memberof SitesService
	 */
	getDrillRigByID(id: string): Observable<DrillRigType> {
		return this._httpClient.get<DrillRigType>(this.apiUrl + 'drill-rig/' + id).pipe(
			tap((response: DrillRigType) => {
				this._drillrig.next(response);
			})
		);
	}

	/**
	 * ### Get All Drillrigs By Site ID
	 *
	 * Busca todos os drillrigs com o mesmo site id
	 *
	 * @param id a ID da entidade a ser buscada
	 * @return uma entidade de drillrig, quando encontrada no banco
	 * @memberof SitesService
	 */
	getAllDrillRigBySiteID(id: string): Observable<DrillRigType[]> {
		return this._httpClient.get<DrillRigType[]>(this.apiUrl + 'drill-rig/site/' + id).pipe(
			tap((response: DrillRigType[]) => {
				this._drillrigsbysiteid.next(response);
			})
		);
	}

	/**
	 * ### Get All RockCodes By Site ID
	 *
	 * Busca todos os RockCodes com o mesmo site id
	 *
	 * @param id a ID da entidade a ser buscada
	 * @return uma entidade de RockCodes, quando encontrada no banco
	 * @memberof SitesService
	 */
	getAllRockCodesBySiteID(id: string): Observable<RockCode[]> {
		return this._httpClient.get<RockCode[]>(this.apiUrl + 'rock-code/site/' + id).pipe(
			tap((response: RockCode[]) => {
				this._rockCodebysiteid.next(response);
			})
		);
	}

	/**
	 * ### Get All Instrument By Site ID
	 *
	 * Busca todos os Instrument com o mesmo site id
	 *
	 * @param id a ID da entidade a ser buscada
	 * @return entidades de Instrument, quando encontrada no banco
	 * @memberof SitesService
	 */
	getAllInstrumentsBySiteID(id: string): Observable<InstrumentType[]> {
		return this._httpClient.get<InstrumentType[]>(this.apiUrl + 'collar-instrument/site/' + id).pipe(
			tap((response: InstrumentType[]) => {
				this._instruments.next(response);
			})
		);
	}

	/**
	 * ### Get All Instrument By Site ID
	 *
	 * Busca todos os Instrument com o mesmo site id
	 *
	 * @param id a ID da entidade a ser buscada
	 * @return entidades de Instrument, quando encontrada no banco
	 * @memberof SitesService
	 */
	getAllStructureDescriptionsBySiteID(id: string): Observable<StructureDescriptionType[]> {
		return this._httpClient.get<StructureDescriptionType[]>(this.apiUrl + 'structure-description/site/' + id).pipe(
			tap((response: StructureDescriptionType[]) => {
				this._structuresbysiteid.next(response);
			})
		);
	}
	/**
	 * ### Get All Budgets by Site ID
	 *
	 * Busca todos os registros de budget com o mesmo site id
	 *
	 * @param id a ID do site a ser buscado
	 * @return entidades de Instrument, quando encontrada no banco
	 * @memberof SitesService
	 */
	getAllBudgetsBySiteID(id: string): Observable<BudgetType[]> {
		return this._httpClient.get<BudgetType[]>(`${this.apiUrl}budget/site/${id}/all`).pipe(
			tap((response: BudgetType[]) => {
				this.budgetsbysite.next(response);
			})
		);
	}
	/**
	 * ### Get All Actuals by Site ID
	 *
	 * Busca todos os registros de actuals com o mesmo site id
	 *
	 * @param id a ID do site a ser buscado
	 * @return entidades de Instrument, quando encontrada no banco
	 * @memberof SitesService
	 */
	getAllActualsBySiteID(id: string): Observable<ActualType[]> {
		return this._httpClient.get<ActualType[]>(`${this.apiUrl}actual/site/${id}/all`).pipe(
			tap((response: ActualType[]) => {
				this.actualsbysite.next(response);
			})
		);
	}

	/**
	 * ### Get All Instrument By Site ID
	 *
	 * Busca todos os Instrument com o mesmo site id
	 *
	 * @param id a ID da entidade a ser buscada
	 * @return entidades de Instrument, quando encontrada no banco
	 * @memberof SitesService
	 */
	getConfigsBySiteID(id: string): Observable<SiteConfigType> {
		return this._httpClient.get<SiteConfigType>(this.apiUrl + 'site-config/', {
			params: { site: id }
		}).pipe(
			tap((response: SiteConfigType) => {
				this._siteconfigsbysiteid.next(response);
			})
		);
	}

	getCollarsBySubsiteID(id: string): Observable<any[]> {
		return this._httpClient.get<any[]>(this.apiUrl + 'subsite/' + id + '/collars');
	}

	getFormsDataBySiteID(id: string): Observable<any> {
		return this._httpClient.get<any>(this.apiUrl+'field/site/'+ id).pipe(
			tap((response: any) => {

				const formFields = {};
				const formNames = [];

				for (const [key, value] of Object.entries(response)) {

					formFields[key.toLocaleLowerCase()] = value;
					formNames.push(key.toLocaleLowerCase());
				};

				localStorage.setItem('formFields', JSON.stringify(formFields));
				localStorage.setItem('formNames', JSON.stringify(formNames));

				this._formdata.next({formFields,formNames});
			}
		));
	};

	goBack(): void {
		this.location.back();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Get boards
	 */
	getBoards(): Observable<Board[]> {
		return this._httpClient.get<Board[]>('api/apps/scrumboard/boards').pipe(
			map(response => response.map(item => new Board(item))),
			tap(boards => this._boards.next(boards))
		);
	}

	/**
	 * Get board
	 *
	 * @param id
	 */
	getBoard(id: string): Observable<Board> {
		return this._httpClient.get<Board>('api/apps/scrumboard/board', { params: { id } }).pipe(
			map(response => new Board(response)),
			tap(board => this._board.next(board))
		);
	}

	/**
	 * Update the list
	 *
	 * @param list
	 */
	updateList(list: List): Observable<List> {
		return this._httpClient.patch<List>('api/apps/scrumboard/board/list', { list }).pipe(
			map(response => new List(response)),
			tap((updatedList) => {

				// Get the board value
				const board = this._board.value;

				// Find the index of the updated list
				const index = board.lists.findIndex(item => item.id === list.id);

				// Update the list
				board.lists[index] = updatedList;

				// Sort the board lists
				board.lists.sort((a, b) => a.position - b.position);

				// Update the board
				this._board.next(board);
			})
		);
	}

	/**
	 * Update the lists
	 *
	 * @param lists
	 */
	updateLists(lists: List[]): Observable<List[]> {
		return this._httpClient.patch<List[]>('api/apps/scrumboard/board/lists', { lists }).pipe(
			map(response => response.map(item => new List(item))),
			tap((updatedLists) => {

				// Get the board value
				const board = this._board.value;

				// Go through the updated lists
				updatedLists.forEach((updatedList) => {

					// Find the index of the updated list
					const index = board.lists.findIndex(item => item.id === updatedList.id);

					// Update the list
					board.lists[index] = updatedList;
				});

				// Sort the board lists
				board.lists.sort((a, b) => a.position - b.position);

				// Update the board
				this._board.next(board);
			})
		);
	}

	/**
	 * Get card
	 */
	getCard(id: string): Observable<Card> {
		return this._board.pipe(
			take(1),
			map((board) => {

				// Find the card
				const card = board.lists.find(list => list.cards.some(item => item.id === id))
					.cards.find(item => item.id === id);

				// Update the card
				this._card.next(card);

				// Return the card
				return card;
			}),
			switchMap((card) => {

				if (!card) {
					return throwError('Could not found the card with id of ' + id + '!');
				}

				return of(card);
			})
		);
	}

	/**
	 * Update the card
	 *
	 * @param id
	 * @param card
	 */
	updateCard(id: string, card: Card): Observable<Card> {
		return this.board$.pipe(
			take(1),
			switchMap(board => this._httpClient.patch<Card>('api/apps/scrumboard/board/card', {
				id,
				card
			}).pipe(
				map((updatedCard) => {

					// Find the card and update it
					board.lists.forEach((listItem) => {
						listItem.cards.forEach((cardItem, index, array) => {
							if (cardItem.id === id) {
								array[index] = updatedCard;
							}
						});
					});

					// Update the board
					this._board.next(board);

					// Update the card
					this._card.next(updatedCard);

					// Return the updated card
					return updatedCard;
				})
			))
		);
	}

	/**
	 * Update the cards
	 *
	 * @param cards
	 */
	updateCards(cards: Card[]): Observable<Card[]> {
		return this._httpClient.patch<Card[]>('api/apps/scrumboard/board/cards', { cards }).pipe(
			map(response => response.map(item => new Card(item))),
			tap((updatedCards) => {

				// Get the board value
				const board = this._board.value;

				// Go through the updated cards
				updatedCards.forEach((updatedCard) => {

					// Find the index of the updated card's list
					const listIndex = board.lists.findIndex(list => list.id === updatedCard.listId);

					// Find the index of the updated card
					const cardIndex = board.lists[listIndex].cards.findIndex(item => item.id === updatedCard.id);

					// Update the card
					board.lists[listIndex].cards[cardIndex] = updatedCard;

					// Sort the cards
					board.lists[listIndex].cards.sort((a, b) => a.position - b.position);
				});

				// Update the board
				this._board.next(board);
			})
		);
	}

	//---

	getSitesGraphData(): Observable<AllSitesGraphData> {
		return this._httpClient.get<AllSitesGraphData>(`${this.apiUrl}dashboard/all-sites`)
		.pipe(
			map((graphData) => {
				return graphData;
			}),
			switchMap((graphData) => {
				if (!graphData) {
					return throwError(
						'Not found!',
					);
				}

				return of(graphData);
			}),
		);
	}
}
