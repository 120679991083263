/* eslint-disable */
export const holes = [
	{
		/*id: 'teste',
		data: {
			drillHoleProgram: [
				{
					id: 'drillHoleProgramId',
					padId: 'SMG007',
					plannedX: 235315.0,
					plannedY: 8720112.0,
					plannedZ: 481.0,
					plannedDepth: 139.30,
					plannedDip: 45,
					plannedAzimuth: 45,
					plannedMeters: 139.30
					//outros dados aqui
				}
			],
			subSiteProgram: [
				{
					id: 'targetProgramId',
					iDTarget: 'SMG - SÃO MIGUEL',
					country: 'Brasil',
					site: 'São Miguel',
					target: 'SMG',
					date: '11/01/2023',
					plannedMeters: 139.30,
					//outros dados aqui
				}
			],
			collar: [
				{ // ! apenas um collar!
					id: 'collarId',
					holeID: 'SMG007',
					x: 235315,
					y: 8720112,
					z: 481,
					depth: 139.30,
					startDate: '01/01/2023',
					endDate: '10/01/2023',
					comments: '',
					drillHoleOperateCompany: 'WILLEMITA',
					project: 'ALM',
					subSite: 'SMG',
					tennement: '862224/1980',
					section: '',
					drillHoleYear: 2022,
					drillHolePurpose: 'SCOUT',
					drillHoleType: 'DD',
					drillingCompany: 'WILLEMITA',
					dhSurveyCompany: 'WILLEMITA',
					coordinateSystem: 'SIGARS2000_23S',
					collarInstrument: 'GPS',
					status: 'Done',
					country: 'Brazil',
					site: 'Almas',
					xYZBy: '',
					xYZDate: '05/01/2023',
					domain: '',
					miningProcess: '',
					coordenateSystem: 'SIGARS2000_23S'
				},
			],
			drillReport: [
				{
					id: 'drillReportId',
					date: '01/01/2023',
					drillRig: 'SD-19',
					shift: 1,
					from: 0,
					to: 0.55,
					advance: 0.55,
					comments: '',
					country: 'Brazil',
					site: 'Almas',
				}
			],
			survey: [
				{
					id: 'surveyId',
					depth: 3,
					dip: -44.65,
					azimuth: 44.82,

					surveyEquipment: '',
					surveyMode: 'reflex',
					surveyBy: '',
					surveyDate: '05/06/2022',

					comments: '',
					country: 'Brazil',
					site: 'Almas',
				}
			],
			boxAndPhotos: [
				{
					id: 'boxAndPhotosiD',
					from: 0.0,
					to: 0.55,
					box: 10,
					photo: 786525,
					photoName: '',
				}
			],
			lithology: [
				{
					id: 'lithoId',
					holeID: 'SMG007',
					from: 0.00,
					to: 2.63,
					lithology: 'SO',
					chloritization: '',
					calcitization: '',
					silicification: '',
					sericitization: '',
					quartzVein: '',
					magnetite: '',
					specularite: '',
					goethite: '',
					goldNuggets: '',
					clay: '',
					hematite: '',
					asPy: '',
					sulfides: '',
					oreType: '',
					lithologyDescription: 'Solo coluvinar argilo-arenoso vermelho, com psolitos lateriticos e fragmentos de quartzo',
					comments: '',
					loggedBy: 'AUGUSTO FONSECA',
					loggedDate: '07/30/2022',
					country: 'Brazil',
					site: 'Almas',
					colour11: '',
					colour11Intensity: '',
					colour21: '',
					colour21Intensity: '',
					secondary: '',
					colour12: '',
					colour12Intensity: '',
					colour22: '',
					colour22Intensity: '',
					material: '',
					oxSul: '',
					argilyc: '',
					prophilityc: '',
					jarGeo: '',
					pyrite: '',
					structure: '',
					dryWet: '',
					garnet: '',
					garnetColor: '',
					pyroxene: '',
					pyroxeneColor: '',
					pyroxeneColorIntst: '',
					garnetPyroxene: '',
					epidote: 0,
					amphibole: 0,
					hornblend: 0,
					malachite: 0,
					azurite: 0,
					chrysocolla: 0,
					cuprite: 0,
					limonite: 0,
					manganeseOxide: 0,
					pyrrhotite: 0,
					chalcopyrite: 0,
					tennantite: 0,
					sphalerite: 0,
					galena: 0,
					molybdenite: 0,
					stibnite: 0,
					enargite: 0,
					bornite: 0,
					chalcocite: 0,
					covellite: 0,
					feldspar: 0,
					biotite: 0,
					muscovite: 0,
					tourmaline: 0,
					wollastonite: 0,
					stockwork: '',
					diss: '',
					vein: '',
					veinlets: '',
					massive: '',
					oxidation: '',
					potassic: '',
					progradeRetrograde: '',
					brecciaZone: '',
					fractureZone: '',
					failZone: '',
					skarnZone: '',
				}
			],
			structure: [
				{
					id: 'structureId',
					from: 0.0,
					to: 1.0,
					comments: '',

					structure: 'S0',
					dip: 45,
					azimuth: 45,

					loggedBy: 'AUGUSTO FONSECA',
					loggedDate: '07/30/2022',
					country: 'Brazil',
					site: 'Almas',
				}
			],
			qtzVein: [
				{
					id: 'qtzveinID',
					from: 0.0,
					to: 1.0,
					thickness: 1.5,
					dip: 32,
					azimuth: 31,

					quartzVein: 'Concordante',
					magnetite: 0,
					specularite: 0,
					goldNuggets: 0,
					clay: 0,
					hematite: 0,
					sulfides: 0,
					asPy: 0,

					comments: '',
					loggedBy: 'AUGUSTO FONSECA',
					loggedDate: '07/30/2022',
				},

			],
			geotech: [
				{
					id: 'geotechId',
					from: 0,
					to: 2.65,
					comments: '',

					recovery: 2.65,
					sumNumPiecesGt10cmMeter: 0,
					rockAlteration: 'W5',
					resistenceR: 'R0',
					resistanceCoefficient: 0,
					spacingF: 'F5',
					spacingCoefficient: 5,
					fill: 0,
					alteration: 0,
					rough: 6,

					h2o: 0,
					srf: 11,
					rqd: 5,
					coefRQD: 3,
					rqdMeters: '3',
					rmr: -1,

					jw: 11,
					jr: 4,
					ja: 20,
					jn: 20,
					q: 0.0067379,
					q2: 0,

					zone: '',
					tcr: 18.4, //fix trc -> tcr
					coefTCR: 18.4,
					lengthIRS: 18.4,
					weathering: 18.4,
					brokenMeters: 18.4,
					rubbleMeters: 18.4,

					loggedBy: 'GERALDO ANDRADE',
					loggedDate: '08/02/2022',
					country: 'Brazil', //select
					site: 'Almas',
				}
			],
			density: [
				{
					id: 'densityId',
					from: 23.09,
					to: 23.20,
					comments: '',

					wetWeightG: 440.0,
					initialWaterLevelMl: 18.4,
					sampleVolumeMl: 18.4,
					densityGcm3: 2.46,

					initialWaterLevelCm: 10.00,
					finalWaterLevelCm: 10.25,
					sampleVolumeCm3: 178.75,

					loggedBy: 'AUGUSTO FONSECA',
					loggedDate: '07/30/2022',
					country: 'Brazil', //select
					site: 'Almas',
				}
			],
			despatch: [
				{
					id: 'despatchId',
					despatch: '01/SM-007',
					laboratory: 'SGS',
					analysisMethod: 'FAA505+ICP40B',
					submittedBy: 'AUGUSTO FONSECA',
					shippingDate: '08/23/2022',
					comments: '',
					country: 'Brazil',
					site: 'Almas',
				}
			],
			samples: [
				{
					id: 'samplesId',
					from: 0,
					to: 0,
					sampleID: 'A-123257',

					weight1: 18.4,
					weight2: 18.4,
					weight3: 18.4,
					sampleType: 'BLK', // select
					checkName: '',
					despatch: '01/SM-007',
					sampleComments: '',
					samplingAgent: 'GERALDO ANDRADE',
					sampleDate: '08/03/2022',
					sourceCheck: '',

					country: 'Brazil', //select
					site: 'Apoena', //select
					idSample: '',
				}
			]
		}*/

	},
];
