import { environment } from 'environments/environment';
import { KeycloakService } from 'keycloak-angular';

export function initializer(keycloak: KeycloakService): () => Promise<boolean> {
	return (): Promise<boolean> =>
		keycloak.init({
			config: environment.auth,
			loadUserProfileAtStartUp: true,
			enableBearerInterceptor: true,
			initOptions: {
				checkLoginIframe: false,
				enableLogging: true,
				onLoad: 'check-sso',
				silentCheckSsoRedirectUri:
					window.location.origin + '/assets/silent-check-sso.html',
			},
		});
}
