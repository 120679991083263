import Dexie, { Table } from 'dexie';

export type Entity = 'collar' | 'structure' | 'lithology' | 'qtz-vein' | 'mineralization'|
	'magnetic-suscepbility' | 'magneticsuscepbility' |  'geotech' | 'density' | 'box-and-photos' | 'drillreport' | 'survey';
export type Operation = 'post' | 'patch' | 'delete';
export type OperationType = `${Entity}::${Operation}`;

export interface PersistData {
	id: string;
	saved: { [index: string]: any };
	file?: Blob;
	operation: OperationType;
}

export class AppDB extends Dexie {
	persistance!: Table<PersistData, string>;

	constructor() {
		super('ExplorationDB');
		this.version(1).stores({ persistance: 'id, operation' });
	}
}

export const db = new AppDB();
