import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KeycloakModule } from 'app/core/authorization/keycloak';
import { SecurityOfflineModule } from 'app/core/authorization/offline';
import { StorageModule } from 'app/core/storage';

let SECURITY_MODULE = [KeycloakModule];

// A value for 'navigator' cannot be determined statically, as it is an external declaration.
// you cannot write conditions using the navigator in the module's 'imports' property
if (!navigator.onLine) {
	SECURITY_MODULE = [SecurityOfflineModule];
}

@NgModule({
	imports: [CommonModule, SECURITY_MODULE, StorageModule],
})
export class AuthorizationModule {}
