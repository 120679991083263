import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api/mock-api.service';

import { holes as holesData } from 'app/mock-api/holes/data';

@Injectable({
	providedIn: 'root'
})
export class HolesMockApi {
	private _holes: any[] = holesData;

	/**
	 * Constructor
	 */
	constructor(private _fuseMockApiService: FuseMockApiService) {
		// Register Mock API handlers
		this.registerHandlers();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Register Mock API handlers
	 */
	registerHandlers(): void {

		// -----------------------------------------------------------------------------------------------------
		// @ Hole, todos - GET
		// -----------------------------------------------------------------------------------------------------
		this._fuseMockApiService
			.onGet('api/holes/holes')
			.reply(() => {

				// Clone the holes
				const holes = cloneDeep(this._holes);

				return [200, holes];
			});

		// -----------------------------------------------------------------------------------------------------
		// @ Hole individual - GET
		// -----------------------------------------------------------------------------------------------------
		this._fuseMockApiService
			.onGet('api/holes/holes/hole')
			.reply(({ request }) => {

				// Get the id from the params
				const id = request.params.get('id');

				// Clone the holes and steps
				const holes = cloneDeep(this._holes);

				// Find the hole and attach steps to it
				const hole = holes.find(item => item.id === id);


				return [
					200,
					hole
				];
			});
	}
}
