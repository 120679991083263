/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';


export const defaultNavigation: FuseNavigationItem[] = [
	{
		id: 'dashboards',
		title: 'Dashboard',
		subtitle: 'Exploration',
		type: 'group',
		icon: 'heroicons_outline:home',
		children: [
			{
				id: 'apps.hole',
				title: 'Drillholes',
				type: 'basic',
				icon: 'heroicons_outline:clipboard-check',
				link: '/apps/hole',
			},
			{
				id: 'sites',
				title: 'Sites',
				type: 'basic',
				icon: 'heroicons_outline:location-marker',
				link: '/apps/sites',
			},
			{
				id: 'dashboards.project',
				title: 'Project Management',
				type: 'basic',
				icon: 'heroicons_outline:globe',
				link: '/dashboards/analytics',
			},
			{
				id: 'apps.tasks',
				title: 'Tasks ',
				type: 'basic',
				icon: 'heroicons_outline:clipboard-check',
				link: '/apps/tasks',
				badge: { title: 'DEV', classes: 'px-2 bg-accent text-white rounded' },
			},/*
			{
				id: 'dashboards.my-project',
				title: 'My hub',
				type: 'basic',
				icon: 'heroicons_outline:newspaper',
				// link: '/dashboards/my-project',
				badge: { title: 'DEV', classes: 'px-2 bg-accent text-white rounded' },
				// badge: { title: '5', classes: 'px-2.5 bg-primary-400 text-black rounded-full' },
				disabled: true

			},
			{
				id: 'dashboards.analytics',
				title: 'Analytics',
				type: 'basic',
				icon: 'heroicons_outline:chart-pie',
				// link: '/dashboards/analytics'
				badge: { title: 'DEV', classes: 'px-2 bg-accent text-white rounded' },
				disabled: true

			},
			{
				id: 'dashboards.finance',
				title: 'Finance',
				type: 'basic',
				icon: 'heroicons_outline:cash',
				//link: '/dashboards/finance',
				badge: { title: 'DEV', classes: 'px-2 bg-accent text-white rounded' },
				disabled: true

			}*/
			{
				id: 'dashboards.title',
				title: 'Mineral Title',
				type: 'basic',
				icon: 'heroicons_outline:briefcase',
				link: '/apps/mineral-title',
				badge: { title: 'DEV', classes: 'px-2 bg-accent text-white rounded' },
			}/*
			{
				id: 'dashboards.laboratory',
				title: 'Laboratory',
				type: 'basic',
				icon: 'heroicons_outline:beaker',
				//link: `/dashboards/laboratory`,
				badge: { title: 'DEV', classes: 'px-2 bg-accent text-white rounded' },
				disabled: true

			}*/
		]
	}
];

export const compactNavigation: FuseNavigationItem[] = [
	{
		id: 'dashboards',
		title: 'Dashboards',
		tooltip: 'Dashboards',
		type: 'aside',
		icon: 'heroicons_outline:home',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	},
	{
		id: 'apps',
		title: 'Apps',
		tooltip: 'Apps',
		type: 'aside',
		icon: 'heroicons_outline:qrcode',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	},
	{
		id: 'pages',
		title: 'Pages',
		tooltip: 'Pages',
		type: 'aside',
		icon: 'heroicons_outline:document-duplicate',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	},
	{
		id: 'user-interface',
		title: 'UI',
		tooltip: 'UI',
		type: 'aside',
		icon: 'heroicons_outline:collection',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	},
	{
		id: 'navigation-features',
		title: 'Navigation',
		tooltip: 'Navigation',
		type: 'aside',
		icon: 'heroicons_outline:menu',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	}
];
export const futuristicNavigation: FuseNavigationItem[] = [
	{
		id: 'dashboards',
		title: 'DASHBOARDS',
		type: 'group',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	},
	{
		id: 'apps',
		title: 'APPS',
		type: 'group',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	},
	{
		id: 'others',
		title: 'OTHERS',
		type: 'group'
	},
	{
		id: 'pages',
		title: 'Pages',
		type: 'aside',
		icon: 'heroicons_outline:document-duplicate',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	},
	{
		id: 'user-interface',
		title: 'User Interface',
		type: 'aside',
		icon: 'heroicons_outline:collection',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	},
	{
		id: 'navigation-features',
		title: 'Navigation Features',
		type: 'aside',
		icon: 'heroicons_outline:menu',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	}
];
export const horizontalNavigation: FuseNavigationItem[] = [
	{
		id: 'dashboards',
		title: 'Dashboards',
		type: 'group',
		icon: 'heroicons_outline:home',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	},
	{
		id: 'apps',
		title: 'Apps',
		type: 'group',
		icon: 'heroicons_outline:qrcode',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	},
	{
		id: 'pages',
		title: 'Pages',
		type: 'group',
		icon: 'heroicons_outline:document-duplicate',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	},
	{
		id: 'user-interface',
		title: 'UI',
		type: 'group',
		icon: 'heroicons_outline:collection',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	},
	{
		id: 'navigation-features',
		title: 'Misc',
		type: 'group',
		icon: 'heroicons_outline:menu',
		children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
	}
];
