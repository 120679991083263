import { NgModule } from '@angular/core';
import { AppGuard, LoginRequiredGuard } from '../guard';
import { SecurityContextSupplier, UserContextSupplier } from '../supplier';
import { OfflineGuard, OfflineLoginRequiredGuard } from './guard';
import { OfflineSecurityContextSupplier } from './offline-security-context-supplier';
import { OfflineUserSupplier } from './offline-user-supplier';
import { OfflineAuthService } from './service';

@NgModule({
	providers: [
		OfflineAuthService,
		{ provide: AppGuard, useClass: OfflineGuard },
		{ provide: LoginRequiredGuard, useClass: OfflineLoginRequiredGuard },
		{
			provide: SecurityContextSupplier,
			useClass: OfflineSecurityContextSupplier,
		},
		{ provide: UserContextSupplier, useClass: OfflineUserSupplier },
		// { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
	],
})
export class SecurityOfflineModule {}
