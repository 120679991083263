import { Injectable } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';
import { StorageKey } from './storage-keys';

@Injectable()
export class StorageService {
	constructor() {}

	set authInfo({
		userInfo,
		token,
		refreshToken,
	}: {
		userInfo: KeycloakProfile;
		token: string;
		refreshToken: string;
	}) {
		localStorage.setItem(StorageKey.USER_INFO, JSON.stringify(userInfo));
		this.updateToken({ token, refreshToken });
	}

	updateToken({
		token,
		refreshToken,
	}: {
		token: string;
		refreshToken: string;
	}) {
		localStorage.setItem(StorageKey.TOKEN, token);
		localStorage.setItem(StorageKey.REFRESH_TOKEN, refreshToken);
	}

	get userInfo(): KeycloakProfile {
		return JSON.parse(localStorage.getItem(StorageKey.USER_INFO));
	}

	get token(): string {
		return localStorage.getItem(StorageKey.TOKEN);
	}

	get refreshToken(): string {
		return localStorage.getItem(StorageKey.REFRESH_TOKEN);
	}

	public clear(): void {
		localStorage.removeItem(StorageKey.USER_INFO);
		localStorage.removeItem(StorageKey.TOKEN);
		localStorage.removeItem(StorageKey.REFRESH_TOKEN);
	}
}
