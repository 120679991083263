import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Hole } from 'app/modules/admin/forms/forms.types';
import { Entity } from 'app/shared/db/db';
import { environment } from 'environments/environment';
import { parse } from 'papaparse';
import {
	BehaviorSubject,
	Observable,
	filter,
	map,
	of,
	switchMap,
	take,
	tap,
	throwError,
} from 'rxjs';
import {
	BoxAndPhotosType,
	CollarRelationshipType,
	CollarType,
	CompanyType,
	DensityType,
	DespatchType,
	DrillHoleProgramType,
	DrillReportType,
	GeotechType,
	LithologyType,
	MagneticSuscepbilityType,
	MineralizationType,
	QaqcSamplesType,
	QtzVeinType,
	SamplesPlanItem,
	SamplesType,
	StructureType,
	SubSiteProgramType,
	SurveyType,
} from './forms.types';

@Injectable({
	providedIn: 'root',
})
export class FormsService {
	apiUrl = environment.url;

	// Private
	private _hole: BehaviorSubject<Hole | null> = new BehaviorSubject(null);
	private _holes: BehaviorSubject<Hole[] | null> = new BehaviorSubject(null);
	private _boxphotos: BehaviorSubject<BoxAndPhotosType[] | null> =
		new BehaviorSubject(null);
	private _qaqcsamples: BehaviorSubject<QaqcSamplesType[] | null> =
		new BehaviorSubject(null);
	private _lithos: BehaviorSubject<LithologyType[] | null> =
		new BehaviorSubject(null);
	private _collar: BehaviorSubject<CollarType | null> = new BehaviorSubject(
		null,
	);
	private _collarrelationship: BehaviorSubject<CollarRelationshipType | null> =
		new BehaviorSubject(null);
	private _subsiteprogram: BehaviorSubject<SubSiteProgramType | null> =
		new BehaviorSubject(null);
	private _drillholeprogram: BehaviorSubject<DrillHoleProgramType | null> =
		new BehaviorSubject(null);
	private _drillreport: BehaviorSubject<DrillReportType | null> =
		new BehaviorSubject(null);
	private _survey: BehaviorSubject<SurveyType | null> = new BehaviorSubject(
		null,
	);
	private _boxandphotos: BehaviorSubject<BoxAndPhotosType | null> =
		new BehaviorSubject(null);
	private _lithology: BehaviorSubject<LithologyType | null> =
		new BehaviorSubject(null);
	private _structure: BehaviorSubject<StructureType | null> =
		new BehaviorSubject(null);
	private _qtzvein: BehaviorSubject<QtzVeinType | null> = new BehaviorSubject(
		null,
	);
	private _geotech: BehaviorSubject<GeotechType | null> = new BehaviorSubject(
		null,
	);
	private _density: BehaviorSubject<DensityType | null> = new BehaviorSubject(
		null,
	);
	private _despatch: BehaviorSubject<DespatchType | null> =
		new BehaviorSubject(null);
	private _samples: BehaviorSubject<SamplesType | null> = new BehaviorSubject(
		null,
	);
	private _samplePlanItens: BehaviorSubject<SamplesPlanItem | null> =
		new BehaviorSubject(null);
	private _latlong: BehaviorSubject<any | null> = new BehaviorSubject(null);

	//agrupados
	private _drillreportsbycollar: BehaviorSubject<DrillReportType[] | null> =
		new BehaviorSubject(null);
	private _surveysbycollar: BehaviorSubject<SurveyType[] | null> =
		new BehaviorSubject(null);
	private _boxandphotosbycollar: BehaviorSubject<BoxAndPhotosType[] | null> =
		new BehaviorSubject(null);
	private _lithologiesbycollar: BehaviorSubject<LithologyType[] | null> =
		new BehaviorSubject(null);
	private _mineralizationsbycollar: BehaviorSubject<
		MineralizationType[] | null
	> = new BehaviorSubject(null);
	private _magneticsuscepbilitesbycollar: BehaviorSubject<
		MagneticSuscepbilityType[] | null
	> = new BehaviorSubject(null);
	private _structuresbycollar: BehaviorSubject<StructureType[] | null> =
		new BehaviorSubject(null);
	private _geotechsbycollar: BehaviorSubject<GeotechType[] | null> =
		new BehaviorSubject(null);
	private _densitiesbycollar: BehaviorSubject<DensityType[] | null> =
		new BehaviorSubject(null);
	private _qtzveinsbycollar: BehaviorSubject<QtzVeinType[] | null> =
		new BehaviorSubject(null);
	private _despatchesbycollar: BehaviorSubject<DespatchType[] | null> =
		new BehaviorSubject(null);
	private _despatcheswithcountbycollar: BehaviorSubject<
		DespatchType[] | null
	> = new BehaviorSubject(null);
	private _samplesbycollar: BehaviorSubject<SamplesType[] | null> =
		new BehaviorSubject(null);
	private _structuralDescriptiosn: BehaviorSubject<any[] | null> =
		new BehaviorSubject(null);

	//all
	private _alldespatches: BehaviorSubject<DespatchType[] | null> =
		new BehaviorSubject(null);
	private _allcompanies: BehaviorSubject<CompanyType[] | null> =
		new BehaviorSubject(null);

	/**
	 * Constructor
	 */
	constructor(
		private readonly httpClient: HttpClient,
		public dialog: MatDialog,
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessores individuais
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for holes
	 */
	get holes$(): Observable<Hole[]> {
		return this._holes.asObservable();
	}

	get boxphotos$(): Observable<BoxAndPhotosType[]> {
		return this._boxphotos.asObservable();
	}

	get lithos$(): Observable<LithologyType[]> {
		return this._lithos.asObservable();
	}

	get alldespatches$(): Observable<DespatchType[]> {
		return this._alldespatches.asObservable();
	}

	/**
	 * Getter for hole
	 */
	get hole$(): Observable<Hole> {
		return this._hole.asObservable();
	}

	/**
	 * Getter for collar
	 */
	get collar$(): Observable<any> {
		return this._collar.asObservable();
	}
	/**
	 * Getter for subsiteprogram
	 */
	get subsiteprogram$(): Observable<SubSiteProgramType> {
		return this._subsiteprogram.asObservable();
	}
	/**
	 * Getter for drillHoleprogram
	 */
	get drillHoleprogram$(): Observable<DrillHoleProgramType> {
		return this._drillholeprogram.asObservable();
	}

	get drillReport$(): Observable<DrillReportType> {
		return this._drillreport.asObservable();
	}

	get lithology$(): Observable<LithologyType> {
		return this._lithology.asObservable();
	}

	get structure$(): Observable<StructureType> {
		return this._structure.asObservable();
	}

	get qtzvein$(): Observable<QtzVeinType> {
		return this._qtzvein.asObservable();
	}

	get geotech$(): Observable<GeotechType> {
		return this._geotech.asObservable();
	}

	get density$(): Observable<DensityType> {
		return this._density.asObservable();
	}

	get boxandphotos$(): Observable<BoxAndPhotosType> {
		return this._boxandphotos.asObservable();
	}

	get despatch$(): Observable<DespatchType> {
		return this._despatch.asObservable();
	}

	get samples$(): Observable<SamplesType> {
		return this._samples.asObservable();
	}

	get survey$(): Observable<SurveyType> {
		return this._survey.asObservable();
	}

	get samplePlanItens$(): Observable<SamplesPlanItem> {
		return this._samplePlanItens.asObservable();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessores agrupados
	// -----------------------------------------------------------------------------------------------------

	get drillreportsbycollar$(): Observable<DrillReportType[]> {
		return this._drillreportsbycollar.asObservable();
	}

	get densitiesbycollar$(): Observable<DensityType[]> {
		return this._densitiesbycollar.asObservable();
	}

	get structuresbycollar$(): Observable<StructureType[]> {
		return this._structuresbycollar.asObservable();
	}

	get samplesbycollar$(): Observable<DespatchType[]> {
		return this._samplesbycollar.asObservable();
	}

	get surveysbycollar$(): Observable<SurveyType[]> {
		return this._surveysbycollar.asObservable();
	}

	get boxandphotosbycollar$(): Observable<BoxAndPhotosType[]> {
		return this._boxandphotosbycollar.asObservable();
	}

	get lithologiesbycollar$(): Observable<LithologyType[]> {
		return this._lithologiesbycollar.asObservable();
	}

	get mineralizationsbycollar$(): Observable<MineralizationType[]> {
		return this._mineralizationsbycollar.asObservable();
	}

	get magneticsuscepbilitesbycollar$(): Observable<
		MagneticSuscepbilityType[]
	> {
		return this._magneticsuscepbilitesbycollar.asObservable();
	}

	get qtzveinsbycollar$(): Observable<QtzVeinType[]> {
		return this._qtzveinsbycollar.asObservable();
	}

	get geotechsbycollar$(): Observable<GeotechType[]> {
		return this._geotechsbycollar.asObservable();
	}

	get despatchesbycollar$(): Observable<DespatchType[]> {
		return this._despatchesbycollar.asObservable();
	}

	get despatcheswithcountbycollar$(): Observable<DespatchType[]> {
		return this._despatcheswithcountbycollar.asObservable();
	}

	get collarrelationship$(): Observable<CollarRelationshipType> {
		return this._collarrelationship.asObservable();
	}

	get allcompanies$(): Observable<CompanyType[]> {
		return this._allcompanies.asObservable();
	}
	get structuralDescription$(): Observable<any[]> {
		return this._structuralDescriptiosn.asObservable();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Get holes
	 */
	getHoles(): Observable<Hole[]> {
		return this.httpClient.get<Hole[]>(this.apiUrl + 'collar/all').pipe(
			tap((response: any) => {
				this._holes.next(response);
			}),
		);
	}

	/**
	 * Get holes
	 */
	getAllBoxAndPhotos(): Observable<BoxAndPhotosType[]> {
		return this.httpClient
			.get<BoxAndPhotosType[]>(this.apiUrl + 'box-and-photos/all')
			.pipe(
				tap((response: any) => {
					this._boxphotos.next(response);
				}),
			);
	}
	/**
	 * Get holes
	 */
	getAllQAQCSamples(): Observable<QaqcSamplesType[]> {
		return this.httpClient
			.get<QaqcSamplesType[]>(this.apiUrl + 'qaqcsamples/all')
			.pipe(
				tap((response: any) => {
					this._qaqcsamples.next(response);
				}),
			);
	}

	/**
	 * Get holes
	 */
	getAllLithology(): Observable<LithologyType[]> {
		return this.httpClient
			.get<LithologyType[]>(this.apiUrl + 'lithology/all')
			.pipe(
				tap((response: any) => {
					this._lithos.next(response);
				}),
			);
	}

	/**
	 * Get drillreport by id
	 */
	getAllDespatches(): Observable<DespatchType[]> {
		return this.httpClient
			.get<DespatchType[]>(this.apiUrl + 'despatch/all')
			.pipe(
				map((alldespatches) => {
					this._alldespatches.next(alldespatches);

					return alldespatches;
				}),
				switchMap((alldespatches) => {
					if (!alldespatches) {
						return throwError(
							'Não foi possível encontrar todos os despachos!',
						);
					}

					return of(alldespatches);
				}),
			);
	}

	/**
	 * Get hole by furoId
	 */
	getHoleById(id: string): Observable<Hole> {
		return this.httpClient.get<Hole>(this.apiUrl + 'collar/' + id).pipe(
			map((hole) => {
				// Update the hole
				this._hole.next(hole);

				// Return the course
				return hole;
			}),
			switchMap((hole) => {
				if (!hole) {
					return throwError(
						'Não foi possível encontrar um furo com o furoId ' +
							id +
							'!',
					);
				}

				return of(hole);
			}),
		);
	}

	/**
	 * Get collar by id
	 */
	getCollarById(id: string): Observable<any> {
		return this.httpClient
			.get<any>(this.apiUrl + 'collar/' + id + '?nested=true')
			.pipe(
				map((collar) => {
					// Update the collar
					this._collar.next(collar);

					// Return the course
					return collar;
				}),
				switchMap((collar) => {
					if (!collar) {
						return throwError(
							'Não foi possível encontrar um collar com o id ' +
								id +
								'!',
						);
					}

					return of(collar);
				}),
			);
	}

	/**
	 * Get collar by id
	 */
	getCollarRelationshipById(id: string): Observable<CollarRelationshipType> {
		return this.httpClient
			.get<CollarRelationshipType>(
				this.apiUrl + 'collar/' + id + '/relationship',
			)
			.pipe(
				map((collarrelationship) => {
					// Update the collar
					this._collarrelationship.next(collarrelationship);

					// Return the course
					return collarrelationship;
				}),
				switchMap((collarrelationship) => {
					if (!collarrelationship) {
						return throwError(
							'Não foi possível encontrar um relacionamento com o id ' +
								id +
								'!',
						);
					}

					return of(collarrelationship);
				}),
			);
	}

	/**
	 * Get subsiteprogram by id
	 */
	getSubSiteProgramById(id: string): Observable<SubSiteProgramType> {
		return this.httpClient
			.get<SubSiteProgramType>(this.apiUrl + 'subsiteprogram/' + id)
			.pipe(
				map((subsiteprogram) => {
					// Update the subsiteprogram
					this._subsiteprogram.next(subsiteprogram);

					// Return the course
					return subsiteprogram;
				}),
				switchMap((subsiteprogram) => {
					if (!subsiteprogram) {
						return throwError(
							'Não foi possível encontrar um subsiteprogram com o id ' +
								id +
								'!',
						);
					}

					return of(subsiteprogram);
				}),
			);
	}
	/**
	 * Get drillholeprogram by id
	 */
	getDrillHoleProgramById(id: string): Observable<DrillHoleProgramType> {
		return this.httpClient
			.get<DrillHoleProgramType>(this.apiUrl + 'drillholeprogram/' + id)
			.pipe(
				map((drillholeprogram) => {
					// Update the subsiteprogram
					this._drillholeprogram.next(drillholeprogram);

					// Return the course
					return drillholeprogram;
				}),
				switchMap((drillholeprogram) => {
					if (!drillholeprogram) {
						return throwError(
							'Não foi possível encontrar um drillholeprogram com o id ' +
								id +
								'!',
						);
					}

					return of(drillholeprogram);
				}),
			);
	}

	/**
	 * Get drillreport by id
	 */
	getDrillReportById(id: string): Observable<DrillReportType> {
		return this.httpClient
			.get<DrillReportType>(this.apiUrl + 'drill-report/' + id)
			.pipe(
				map((drillreport) => {
					// Update the subsiteprogram
					this._drillreport.next(drillreport);

					// Return the course
					return drillreport;
				}),
				switchMap((drillreport) => {
					if (!drillreport) {
						return throwError(
							'Não foi possível encontrar um drillreport com o id ' +
								id +
								'!',
						);
					}

					return of(drillreport);
				}),
			);
	}

	/**
	 * Get drillreport by id
	 */
	getDrillReportsByCollarId(id: string): Observable<any[]> {
		return this.httpClient
			.get<any[]>(
				this.apiUrl + 'drill-report/collar/' + id + '?nested=true',
			)
			.pipe(
				map((drillreportsbycollar) => {
					// Update the subsiteprogram
					this._drillreportsbycollar.next(drillreportsbycollar);

					// Return the course
					return drillreportsbycollar;
				}),
				switchMap((drillreportsbycollar) => {
					if (!drillreportsbycollar) {
						return throwError(
							'Não foi possível encontrar um drillreport com o id ' +
								id +
								'!',
						);
					}

					return of(drillreportsbycollar);
				}),
			);
	}

	/**
	 * Get survey by id
	 */
	getSurveyById(id: string): Observable<SurveyType> {
		return this.httpClient
			.get<SurveyType>(this.apiUrl + 'survey/' + id)
			.pipe(
				map((survey) => {
					// Update the subsiteprogram
					this._survey.next(survey);

					// Return the course
					return survey;
				}),
				switchMap((survey) => {
					if (!survey) {
						return throwError(
							'Não foi possível encontrar um survey com o id ' +
								id +
								'!',
						);
					}

					return of(survey);
				}),
			);
	}

	/**
	 * Get all surveys by collar id
	 */
	getSurveysByCollarID(id: string): Observable<SurveyType[]> {
		return this.httpClient
			.get<SurveyType[]>(this.apiUrl + 'survey/collar/' + id)
			.pipe(
				map((surveysbycollar) => {
					// Update the subsiteprogram
					this._surveysbycollar.next(surveysbycollar);

					// Return the course
					return surveysbycollar;
				}),
				switchMap((surveysbycollar) => {
					if (!surveysbycollar) {
						return throwError(
							'Não foi possível encontrar um survey com o id ' +
								id +
								'!',
						);
					}

					return of(surveysbycollar);
				}),
			);
	}

	/**
	 * Get b-a-p by id
	 */
	getBoxAndPhotosById(id: string): Observable<BoxAndPhotosType> {
		return this.httpClient
			.get<BoxAndPhotosType>(this.apiUrl + 'box-and-photos/' + id)
			.pipe(
				map((boxandphotos) => {
					// Update the subsiteprogram
					this._boxandphotos.next(boxandphotos);

					// Return the course
					return boxandphotos;
				}),
				switchMap((boxandphotos) => {
					if (!boxandphotos) {
						return throwError(
							'Não foi possível encontrar um box and photos com o id ' +
								id +
								'!',
						);
					}

					return of(boxandphotos);
				}),
			);
	}
	/**
	 * Get b-a-p by id
	 */
	getBoxAndPhotosByCollarID(id: string): Observable<BoxAndPhotosType[]> {
		return this.httpClient
			.get<BoxAndPhotosType[]>(
				this.apiUrl + 'box-and-photos/collar/' + id,
			)
			.pipe(
				map((boxandphotosbycollar: any[]) => {
					let photos = [];
					let teste;

					this.httpClient
						.get(`${this.apiUrl}photo/collar/${id}`)
						.subscribe({
							next: (success: any) => {
								photos = [...success];
							},
							error: (error: any) => {
								console.error(error.error.message);
							},
						});

					// Update the subsiteprogram
					this._boxandphotosbycollar.next(boxandphotosbycollar);

					// Return the course
					return boxandphotosbycollar;
				}),
				switchMap((boxandphotosbycollar) => {
					if (!boxandphotosbycollar) {
						return throwError(
							'Não foi possível encontrar um box and photos com o id ' +
								id +
								'!',
						);
					}

					return of(boxandphotosbycollar);
				}),
			);
	}

	/**
	 * Get litho by collar id
	 */
	getLithologiesByCollarID(id: string): Observable<LithologyType[]> {
		return this.httpClient
			.get<LithologyType[]>(this.apiUrl + 'lithology/collar/' + id)
			.pipe(
				map((lithologiesbycollar) => {
					// Update the subsiteprogram
					this._lithologiesbycollar.next(lithologiesbycollar);

					// Return the course
					return lithologiesbycollar;
				}),
				switchMap((lithologiesbycollar) => {
					if (!lithologiesbycollar) {
						return throwError(
							'Não foi possível encontrar um lithology com o id ' +
								id +
								'!',
						);
					}

					return of(lithologiesbycollar);
				}),
			);
	}

	/**
	 * Get mineralization by collar id
	 */
	getMagneticSuscepbilitesByCollarID(
		id: string,
	): Observable<MagneticSuscepbilityType[]> {
		return this.httpClient
			.get<MagneticSuscepbilityType[]>(
				this.apiUrl + 'magnetic-suscepbility/collar/' + id,
			)
			.pipe(
				map((magneticsuscepbilitesbycollar) => {
					// Update the magneticsuscepbilitesbycollar
					this._magneticsuscepbilitesbycollar.next(
						magneticsuscepbilitesbycollar,
					);

					// Return the mineralizationsbycollar
					return magneticsuscepbilitesbycollar;
				}),
				switchMap((magneticsuscepbilitesbycollar) => {
					if (!magneticsuscepbilitesbycollar) {
						return throwError(
							'Não foi possível encontrar uma mag com o id ' +
								id +
								'!',
						);
					}

					return of(magneticsuscepbilitesbycollar);
				}),
			);
	}

	/**
	 * Get mineralization by collar id
	 */
	getMineralizationsByCollarID(id: string): Observable<MineralizationType[]> {
		return this.httpClient
			.get<MineralizationType[]>(
				this.apiUrl + 'mineralization/collar/' + id,
			)
			.pipe(
				map((mineralizationsbycollar) => {
					// Update the mineralizationsbycollar
					this._mineralizationsbycollar.next(mineralizationsbycollar);

					// Return the mineralizationsbycollar
					return mineralizationsbycollar;
				}),
				switchMap((mineralizationsbycollar) => {
					if (!mineralizationsbycollar) {
						return throwError(
							'Não foi possível encontrar uma mineralizacao com o id ' +
								id +
								'!',
						);
					}

					return of(mineralizationsbycollar);
				}),
			);
	}

	/**
	 * Get litho by id
	 */
	getLithologyById(id: string): Observable<LithologyType> {
		return this.httpClient
			.get<LithologyType>(this.apiUrl + 'lithology/' + id)
			.pipe(
				map((lithology) => {
					// Update the subsiteprogram
					this._lithology.next(lithology);

					// Return the course
					return lithology;
				}),
				switchMap((lithology) => {
					if (!lithology) {
						return throwError(
							'Não foi possível encontrar um lithology com o id ' +
								id +
								'!',
						);
					}

					return of(lithology);
				}),
			);
	}

	/**
	 * Get utm to lat long convertion
	 */
	convertUtmToLatLong(
		utmX: number,
		utmY: number,
		epsg: number,
	): Observable<any> {
		return this.httpClient
			.post<any>(this.apiUrl + 'collar/convert-utm-latlong', {
				body: { utmX, utmY, epsg },
			})
			.pipe(
				map((data) => {
					// Return
					return data;
				}),
				switchMap((data) => {
					if (!data) {
						return throwError('Não foi possível converter !');
					}

					return of(data);
				}),
			);
	}

	/**
	 * Get structure by id
	 */
	getStructureById(id: string): Observable<StructureType> {
		return this.httpClient
			.get<StructureType>(this.apiUrl + 'structure/' + id)
			.pipe(
				map((structure) => {
					// Update the subsiteprogram
					this._structure.next(structure);

					// Return the course
					return structure;
				}),
				switchMap((structure) => {
					if (!structure) {
						return throwError(
							'Não foi possível encontrar um structure com o id ' +
								id +
								'!',
						);
					}

					return of(structure);
				}),
			);
	}

	/**
	 * Get structures by collarid
	 */
	getStructuresByCollarId(id: string): Observable<StructureType[]> {
		return this.httpClient
			.get<StructureType[]>(
				this.apiUrl + 'structure/collar/' + id + '?nested=true',
			)
			.pipe(
				map((structuresbycollar) => {
					// Update the subsiteprogram
					this._structuresbycollar.next(structuresbycollar);

					// Return the course
					return structuresbycollar;
				}),
				switchMap((structuresbycollar) => {
					if (!structuresbycollar) {
						return throwError(
							'Não foi possível encontrar um structure com o id ' +
								id +
								'!',
						);
					}

					return of(structuresbycollar);
				}),
			);
	}

	/**
	 * Get qtz vein by id
	 */
	getQtzVeinById(id: string): Observable<QtzVeinType> {
		return this.httpClient
			.get<QtzVeinType>(this.apiUrl + 'qtz-vein/' + id)
			.pipe(
				map((qtzvein) => {
					// Update the subsiteprogram
					this._qtzvein.next(qtzvein);

					// Return the course
					return qtzvein;
				}),
				switchMap((qtzvein) => {
					if (!qtzvein) {
						return throwError(
							'Não foi possível encontrar um qtzvein com o id ' +
								id +
								'!',
						);
					}

					return of(qtzvein);
				}),
			);
	}

	/**
	 * Get qtz vein by id
	 */
	getQtzVeinsByCollarID(id: string): Observable<QtzVeinType[]> {
		return this.httpClient
			.get<QtzVeinType[]>(this.apiUrl + 'qtz-vein/collar/' + id)
			.pipe(
				map((qtzveinsbycollar) => {
					// Update the subsiteprogram
					this._qtzveinsbycollar.next(qtzveinsbycollar);

					// Return the course
					return qtzveinsbycollar;
				}),
				switchMap((qtzveinsbycollar) => {
					if (!qtzveinsbycollar) {
						return throwError(
							'Não foi possível encontrar um qtzveinsbycollar com o id ' +
								id +
								'!',
						);
					}

					return of(qtzveinsbycollar);
				}),
			);
	}

	/**
	 * Get geotech by id
	 */
	getGeotechById(id: string): Observable<GeotechType> {
		return this.httpClient
			.get<GeotechType>(this.apiUrl + 'geotech/' + id)
			.pipe(
				map((geotech) => {
					// Update the subsiteprogram
					this._geotech.next(geotech);

					// Return the course
					return geotech;
				}),
				switchMap((geotech) => {
					if (!geotech) {
						return throwError(
							'Não foi possível encontrar um geotech com o id ' +
								id +
								'!',
						);
					}

					return of(geotech);
				}),
			);
	}

	/**
	 * Get geotech by collar id
	 */
	getGeotechsByCollarID(id: string): Observable<GeotechType[]> {
		return this.httpClient
			.get<GeotechType[]>(this.apiUrl + 'geotech/collar/' + id)
			.pipe(
				map((geotechsbycollar) => {
					// Update the subsiteprogram
					this._geotechsbycollar.next(geotechsbycollar);

					// Return the course
					return geotechsbycollar;
				}),
				switchMap((geotechsbycollar) => {
					if (!geotechsbycollar) {
						return throwError(
							'Não foi possível encontrar um geotechsbycollar com o id ' +
								id +
								'!',
						);
					}

					return of(geotechsbycollar);
				}),
			);
	}

	/**
	 * Get density by id
	 */
	getDensityById(id: string): Observable<DensityType> {
		return this.httpClient
			.get<DensityType>(this.apiUrl + 'density/' + id)
			.pipe(
				map((density) => {
					// Update the subsiteprogram
					this._density.next(density);

					// Return the course
					return density;
				}),
				switchMap((density) => {
					if (!density) {
						return throwError(
							'Não foi possível encontrar um geotech com o id ' +
								id +
								'!',
						);
					}

					return of(density);
				}),
			);
	}
	/**
	 * Get drillreport by id
	 */
	getDensitiesByCollarID(id: string): Observable<DensityType[]> {
		return this.httpClient
			.get<DensityType[]>(this.apiUrl + 'density/collar/' + id)
			.pipe(
				map((densitiesbycollar) => {
					// Update the subsiteprogram
					this._densitiesbycollar.next(densitiesbycollar);

					// Return the course
					return densitiesbycollar;
				}),
				switchMap((densitiesbycollar) => {
					if (!densitiesbycollar) {
						return throwError(
							'Não foi possível encontrar um density com o id ' +
								id +
								'!',
						);
					}

					return of(densitiesbycollar);
				}),
			);
	}

	/**
	 * Get despatch by id
	 */
	getDespatchById(id: string): Observable<DespatchType> {
		return this.httpClient
			.get<DespatchType>(this.apiUrl + 'despatch/' + id)
			.pipe(
				map((despatch) => {
					// Update the subsiteprogram
					this._despatch.next(despatch);

					// Return the course
					return despatch;
				}),
				switchMap((despatch) => {
					if (!despatch) {
						return throwError(
							'Não foi possível encontrar um despatch com o id ' +
								id +
								'!',
						);
					}

					return of(despatch);
				}),
			);
	}

	/**
	 * Get despatch by id
	 */
	getDespatchesByCollarID(id: string): Observable<DespatchType[]> {
		return this.httpClient
			.get<DespatchType[]>(this.apiUrl + 'despatch/collar/' + id)
			.pipe(
				map((despatchesbycollar) => {
					// Update the subsiteprogram
					this._despatchesbycollar.next(despatchesbycollar);

					// Return the course
					return despatchesbycollar;
				}),
				switchMap((despatchesbycollar) => {
					if (!despatchesbycollar) {
						return throwError(
							'Não foi possível encontrar um despatchesbycollar com o id ' +
								id +
								'!',
						);
					}

					return of(despatchesbycollar);
				}),
			);
	}
	/**
	 * Get despatch by id
	 */
	///despatch/all/collar/{id}/samplescount
	getDespatchesWithCountByCollarID(id: string): Observable<DespatchType[]> {
		return this.httpClient
			.get<DespatchType[]>(
				`${this.apiUrl}despatch/all/collar/${id}/samplescount`,
			)
			.pipe(
				map((despatcheswithcountbycollar) => {
					// Update the subsiteprogram
					this._despatcheswithcountbycollar.next(
						despatcheswithcountbycollar,
					);

					// Return the course
					return despatcheswithcountbycollar;
				}),
				switchMap((despatcheswithcountbycollar) => {
					if (!despatcheswithcountbycollar) {
						return throwError(
							'Não foi possível encontrar um despatchesbycollar com o id ' +
								id +
								'!',
						);
					}

					return of(despatcheswithcountbycollar);
				}),
			);
	}

	/**
	 * Get despatch by id
	 */
	getSamplesById(id: string): Observable<SamplesType> {
		return this.httpClient
			.get<SamplesType>(this.apiUrl + 'samples/' + id)
			.pipe(
				map((samples) => {
					// Update the subsiteprogram
					this._samples.next(samples);

					// Return the course
					return samples;
				}),
				switchMap((samples) => {
					if (!samples) {
						return throwError(
							'Não foi possível encontrar um samples com o id ' +
								id +
								'!',
						);
					}

					return of(samples);
				}),
			);
	}

	/**
	 * Get drillreport by id
	 */
	getSamplesByCollarID(id: string): Observable<SamplesType[]> {
		return this.httpClient
			.get<SamplesType[]>(
				this.apiUrl + 'samples/collar/' + id + `?nested=true`,
			)
			.pipe(
				map((samplesbycollar) => {
					// Update the subsiteprogram
					this._samplesbycollar.next(samplesbycollar);

					// Return the course
					return samplesbycollar;
				}),
				switchMap((samplesbycollar) => {
					if (!samplesbycollar) {
						return throwError(
							'Não foi possível encontrar um drillreport com o id ' +
								id +
								'!',
						);
					}

					return of(samplesbycollar);
				}),
			);
	}

	/**
	 * Patch collar
	 *
	 *
	 * @param id
	 * @param collar
	 */
	patchCollar(id: string, data: any): Observable<CollarType> {
		return this.collar$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.patch<CollarType>(this.apiUrl + 'collar/' + id, data)
					.pipe(
						map(
							(updatedCollar) =>
								// Return the updated collar
								updatedCollar,
						),
						switchMap((updatedCollar) =>
							this.collar$.pipe(
								take(1),
								filter((item) => item && item.id === id),
								tap(() => {
									// Update the collar if	 it's selected
									this._collar.next(updatedCollar);

									// Return the updated collar
									return updatedCollar;
								}),
							),
						),
					),
			),
		);
	}
	/**
	 * Patch targtprogram
	 *
	 *
	 * @param id
	 * @param subsiteprogram
	 */
	patchSubSiteProgram(id: string, data: any): Observable<SubSiteProgramType> {
		return this.subsiteprogram$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.patch<SubSiteProgramType>(
						this.apiUrl + 'subsiteprogram/' + id,
						data,
					)
					.pipe(
						map(
							(updatedSubSiteProgram) =>
								// Return the updated subsiteprogram
								updatedSubSiteProgram,
						),
						switchMap((updatedSubSiteProgram) =>
							this.subsiteprogram$.pipe(
								take(1),
								filter((item) => item && item.id === id),
								tap(() => {
									// Update the subsiteprogram if	 it's selected
									this._subsiteprogram.next(
										updatedSubSiteProgram,
									);

									// Return the updated subsiteprogram
									return updatedSubSiteProgram;
								}),
							),
						),
					),
			),
		);
	}
	/**
	 * Patch drillhole program
	 *
	 *
	 * @param id
	 * @param drillholeprogram
	 */
	patchDrillHoleProgram(
		id: string,
		data: any,
	): Observable<DrillHoleProgramType> {
		return this.drillHoleprogram$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.patch<DrillHoleProgramType>(
						this.apiUrl + 'drillholeprogram/' + id,
						data,
					)
					.pipe(
						map(
							(updatedDrillHoleProgram) =>
								// Return the updated subsiteprogram
								updatedDrillHoleProgram,
						),
						switchMap((updatedDrillHoleProgram) =>
							this.drillHoleprogram$.pipe(
								take(1),
								filter((item) => item && item.id === id),
								tap(() => {
									// Update the subsiteprogram if	 it's selected
									this._drillholeprogram.next(
										updatedDrillHoleProgram,
									);

									// Return the updated subsiteprogram
									return updatedDrillHoleProgram;
								}),
							),
						),
					),
			),
		);
	}

	/**
	 * Patch drill report
	 *
	 *
	 * @param id
	 * @param drillreport
	 */
	patchDrillReport(id: string, data: any): Observable<any> {
		return this.drillReport$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.patch<any>(this.apiUrl + 'drill-report/' + id, data)
					.pipe(
						map(
							(updatedDrillReport) =>
								// Return the updated subsiteprogram
								updatedDrillReport,
						),
						switchMap((updatedDrillReport) =>
							this.drillReport$.pipe(
								take(1),
								filter((item) => item && item.id === id),
								tap(() => {
									// Update the subsiteprogram if	 it's selected
									this._drillreport.next(updatedDrillReport);

									// Return the updated subsiteprogram
									return updatedDrillReport;
								}),
							),
						),
					),
			),
		);
	}

	/**
	 * post drill report
	 *
	 * @param data
	 */
	postDrillReport(data: any): Observable<DrillReportType> {
		return this.drillReport$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.post<DrillReportType>(this.apiUrl + 'drill-report', data)
					.pipe(
						map(
							(newDrillReport) =>
								// Return the updated
								newDrillReport,
						),
						switchMap((newDrillReport) =>
							this.drillReport$.pipe(
								take(1),
								tap(() => {
									// Update the  if	 it's selected
									this._drillreport.next(newDrillReport);

									// Return the updated
									return newDrillReport;
								}),
							),
						),
					),
			),
		);
	}

	/**
	 * post box and photos
	 *
	 * @param data
	 */
	postBoxAndPhotos(data: any): Observable<BoxAndPhotosType> {
		return this.boxandphotos$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.post<BoxAndPhotosType>(
						this.apiUrl + 'box-and-photos',
						data,
					)
					.pipe(
						map(
							(newBoxAndPhotos) =>
								// Return the updated
								newBoxAndPhotos,
						),
						switchMap((newBoxAndPhotos) =>
							this.boxandphotos$.pipe(
								take(1),
								tap(() => {
									// Update the  if	 it's selected
									this._boxandphotos.next(newBoxAndPhotos);

									// Return the updated
									return newBoxAndPhotos;
								}),
							),
						),
					),
			),
		);
	}
	/**
	 * post lithology
	 *
	 * @param data
	 */
	postLithology(data: any): Observable<LithologyType> {
		return this.lithology$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.post<LithologyType>(this.apiUrl + 'lithology', data)
					.pipe(
						map(
							(newLithology) =>
								// Return the updated
								newLithology,
						),
						switchMap((newLithology) =>
							this.lithology$.pipe(
								take(1),
								tap(() => {
									// Update the  if	 it's selected
									this._lithology.next(newLithology);

									// Return the updated
									return newLithology;
								}),
							),
						),
					),
			),
		);
	}
	/**
	 * post survey
	 *
	 * @param data
	 */
	postSurvey(data: any): Observable<SurveyType> {
		return this.survey$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.post<SurveyType>(this.apiUrl + 'survey', data)
					.pipe(
						map(
							(newSurvey) =>
								// Return the updated
								newSurvey,
						),
						switchMap((newSurvey) =>
							this.survey$.pipe(
								take(1),
								tap(() => {
									// Update the  if	 it's selected
									this._lithology.next(newSurvey);

									// Return the updated
									return newSurvey;
								}),
							),
						),
					),
			),
		);
	}

	/**
	 * post drill report
	 *
	 * @param data
	 */
	postCollar(data: any): Observable<CollarType> {
		return this.collar$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.post<CollarType>(this.apiUrl + 'collar', data)
					.pipe(
						map(
							(newCollar) =>
								// Return the updated
								newCollar,
						),
					),
			),
		);
	}

	/**
	 * Patch survey
	 *
	 *
	 * @param id
	 * @param survey
	 */
	patchSurvey(id: string, data: any): Observable<SurveyType> {
		return this.survey$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.patch<SurveyType>(this.apiUrl + 'survey/' + id, data)
					.pipe(
						map(
							(updatedSurvey) =>
								// Return the updated subsiteprogram
								updatedSurvey,
						),
						switchMap((updatedSurvey) =>
							this.survey$.pipe(
								take(1),
								filter((item) => item && item.id === id),
								tap(() => {
									// Update the subsiteprogram if	 it's selected
									this._survey.next(updatedSurvey);

									// Return the updated subsiteprogram
									return updatedSurvey;
								}),
							),
						),
					),
			),
		);
	}

	/**
	 * Patch boxandphotos
	 *
	 *
	 * @param id
	 * @param boxandphotos
	 */
	patchBoxAndPhotos(id: string, data: any): Observable<BoxAndPhotosType> {
		return this.boxandphotos$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.patch<BoxAndPhotosType>(
						this.apiUrl + 'box-and-photos/' + id,
						data,
					)
					.pipe(
						map(
							(updatedBoxAndPhotos) =>
								// Return the updated subsiteprogram
								updatedBoxAndPhotos,
						),
						switchMap((updatedBoxAndPhotos) =>
							this.boxandphotos$.pipe(
								take(1),
								filter((item) => item && item.id === id),
								tap(() => {
									// Update the subsiteprogram if	 it's selected
									this._boxandphotos.next(
										updatedBoxAndPhotos,
									);

									// Return the updated subsiteprogram
									return updatedBoxAndPhotos;
								}),
							),
						),
					),
			),
		);
	}

	/**
	 * Patch boxandphotos
	 *
	 *
	 * @param id
	 * @param lithology
	 */
	patchLithology(id: string, data: any): Observable<LithologyType> {
		return this.lithology$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.patch<LithologyType>(this.apiUrl + 'lithology/' + id, data)
					.pipe(
						map(
							(updatedLithology) =>
								// Return the updated subsiteprogram
								updatedLithology,
						),
						switchMap((updatedLithology) =>
							this.lithology$.pipe(
								take(1),
								filter((item) => item && item.id === id),
								tap(() => {
									// Update the subsiteprogram if	 it's selected
									this._lithology.next(updatedLithology);

									// Return the updated subsiteprogram
									return updatedLithology;
								}),
							),
						),
					),
			),
		);
	}

	/**
	 * Patch boxandphotos
	 *
	 *
	 * @param id
	 * @param structure
	 */
	patchStructure(id: string, data: any): Observable<StructureType> {
		return this.structure$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.patch<StructureType>(this.apiUrl + 'structure/' + id, data)
					.pipe(
						map(
							(updatedStructure) =>
								// Return the updated subsiteprogram
								updatedStructure,
						),
						switchMap((updatedStructure) =>
							this.structure$.pipe(
								take(1),
								filter((item) => item && item.id === id),
								tap(() => {
									// Update the subsiteprogram if	 it's selected
									this._structure.next(updatedStructure);

									// Return the updated subsiteprogram
									return updatedStructure;
								}),
							),
						),
					),
			),
		);
	}

	/**
	 * Patch boxandphotos
	 *
	 *
	 * @param id
	 * @param qtzvein
	 */
	patchQtzVein(id: string, data: any): Observable<QtzVeinType> {
		return this.qtzvein$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.patch<QtzVeinType>(this.apiUrl + 'qtz-vein/' + id, data)
					.pipe(
						map(
							(updatedQtzVein) =>
								// Return the updated subsiteprogram
								updatedQtzVein,
						),
						switchMap((updatedQtzVein) =>
							this.qtzvein$.pipe(
								take(1),
								filter((item) => item && item.id === id),
								tap(() => {
									// Update the subsiteprogram if	 it's selected
									this._qtzvein.next(updatedQtzVein);

									// Return the updated subsiteprogram
									return updatedQtzVein;
								}),
							),
						),
					),
			),
		);
	}

	/**
	 * Patch boxandphotos
	 *
	 *
	 * @param id
	 * @param geotech
	 */
	patchGeotech(id: string, data: any): Observable<GeotechType> {
		return this.geotech$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.patch<GeotechType>(this.apiUrl + 'geotech/' + id, data)
					.pipe(
						map(
							(updatedGeotech) =>
								// Return the updated subsiteprogram
								updatedGeotech,
						),
						switchMap((updatedGeotech) =>
							this.geotech$.pipe(
								take(1),
								filter((item) => item && item.id === id),
								tap(() => {
									// Update the subsiteprogram if	 it's selected
									this._geotech.next(updatedGeotech);

									// Return the updated subsiteprogram
									return updatedGeotech;
								}),
							),
						),
					),
			),
		);
	}
	/**
	 * Patch boxandphotos
	 *
	 *
	 * @param id
	 * @param density
	 */
	patchDensity(id: string, data: any): Observable<DensityType> {
		return this.density$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.patch<DensityType>(this.apiUrl + 'density/' + id, data)
					.pipe(
						map(
							(updatedDensity) =>
								// Return the updated subsiteprogram
								updatedDensity,
						),
						switchMap((updatedDensity) =>
							this.density$.pipe(
								take(1),
								filter((item) => item && item.id === id),
								tap(() => {
									// Update the subsiteprogram if	 it's selected
									this._density.next(updatedDensity);

									// Return the updated subsiteprogram
									return updatedDensity;
								}),
							),
						),
					),
			),
		);
	}

	/**
	 * Patch despatch
	 *
	 *
	 * @param id
	 * @param despatch
	 */
	patchDespatch(id: string, data: any): Observable<DespatchType> {
		return this.despatch$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.patch<DespatchType>(this.apiUrl + 'despatch/' + id, data)
					.pipe(
						map(
							(updatedDespatch) =>
								// Return the updated subsiteprogram
								updatedDespatch,
						),
						switchMap((updatedDespatch) =>
							this.despatch$.pipe(
								take(1),
								filter((item) => item && item.id === id),
								tap(() => {
									// Update the subsiteprogram if	 it's selected
									this._despatch.next(updatedDespatch);

									// Return the updated subsiteprogram
									return updatedDespatch;
								}),
							),
						),
					),
			),
		);
	}

	/**
	 * Patch samples
	 *
	 *
	 * @param id
	 * @param samples
	 */
	patchSamples(id: string, data: any): Observable<SamplesType> {
		return this.samples$.pipe(
			take(1),
			switchMap((dados) =>
				this.httpClient
					.patch<SamplesType>(this.apiUrl + 'samples/' + id, data)
					.pipe(
						map(
							(updatedSamples) =>
								// Return the updated subsiteprogram
								updatedSamples,
						),
						switchMap((updatedSamples) =>
							this.samples$.pipe(
								take(1),
								filter((item) => item && item.id === id),
								tap(() => {
									// Update the subsiteprogram if	 it's selected
									this._samples.next(updatedSamples);

									// Return the updated subsiteprogram
									return updatedSamples;
								}),
							),
						),
					),
			),
		);
	}

	/**
	 * ### Get entity
	 *
	 * @param entityType o tipo da entidade, consulte o swagger para saber as opções
	 * @param id o id único da entidade a ser requisitada
	 */
	getEntity(entityType: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.apiUrl}${entityType}/` + id);
	}

	/**
	 * ### Get entity group
	 *
	 * @param entityType o tipo da entidade, consulte o swagger para saber as opções
	 */
	getEntityGroup(entityType: string): Observable<any> {
		return this.httpClient.get<any>(`${this.apiUrl}${entityType}/`);
	}

	/**
	 * ### Patch entity
	 *
	 * @param entityType o tipo da entidade, consulte o swagger para saber as opções
	 * @param id o id único da entidade a ser editada
	 * @param data o corpo da entidade contendo novos dados
	 */
	patchEntity(entityType: string, id: string, data: any): Observable<any> {
		return this.httpClient.patch<any>(
			`${this.apiUrl}${entityType}/${id}`,
			data,
		);
	}

	/**
	 * ### Patch entity
	 *
	 * @param entityType o tipo da entidade, consulte o swagger para saber as opções
	 * @param id o id único da entidade a ser editada
	 * @param data o corpo da entidade contendo novos dados
	 */
	patchManyEntities(entityType: string, data: any): Observable<any> {
		return this.httpClient.patch<any>(
			`${this.apiUrl}${entityType}/`,
			data,
		);
	}

	/**
	 * ### Post entity
	 *
	 * @param entityType o tipo da entidade, consulte o swagger para saber as opções
	 * @param data o corpo da entidade contendo novos dados
	 */
	postEntity(entityType: string, data: any): Observable<any> {
		return this.httpClient.post<any>(`${this.apiUrl}${entityType}`, data);
	}

	/**
	 * ### Post entity
	 *
	 * @param entityType o tipo da entidade, consulte o swagger para saber as opções
	 * @param data o corpo da entidade contendo novos dados
	 */
	postEntityParam(entityType: string, data: any, param?: any): Observable<any> {
		return this.httpClient.post<any>(`${this.apiUrl}${entityType}`, data, param);
	}

	/**
	 * ### Delete entity
	 *
	 * Deleta uma entidade baseada em seu tipo e id.
	 *
	 * @param entityType o tipo da entidade, consulte o swagger para saber as opções
	 * @param id o id único da entidade a ser removida
	 */
	deleteEntity(entityType: string, id: string): Observable<any> {
		return this.httpClient.delete<any>(
			`${this.apiUrl}${entityType}/${id}`,
		);
	}
	/**
	 * ### Delete many
	 *
	 * Deleta varios registros de uma  entidade baseada em seu id.
	 *
	 * @param entityType o tipo da entidade, consulte o swagger para saber as opções
	 * @param ids ids do registro a serem removidos
	 */
	deleteMany(entityType: string, ids: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.apiUrl}${entityType}`, {
			body: ids,
		});
	}

	/**
	 * ### Post multipart photo entity
	 *
	 * @param entityType o tipo da entidade, consulte o swagger para saber as opções
	 * @param data o corpo da entidade contendo novos dados
	 */
	postPhotoEntity(
		boxAndPhotosId: string,
		fileToUpload: File,
	): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('file', fileToUpload);
		formData.append('boxAndPhotosId', boxAndPhotosId);
		return this.httpClient.post<any>(
			this.apiUrl + 'photo/upload',
			formData,
		);
	}

	/**
	 * Update Minimum Value
	 *
	 * @description Atualiza o valor mínimo que é posível de ser utilizado em um campo
	 * @param event evento que aciona a função
	 * @return	um número que representa o valor mínimo que pode ser utilizado
	 * @memberof FormsService
	 */
	updateMinimumValue(event: any): number {
		return +event.target.value + 0.05;
	}

	/**
	 * Update Max
	 *
	 * Essa função serve para encontrar qual o maior
	 * valor do campo TO dentro de uma entidade
	 *
	 * Ajustes podem ser feitos para retorar a maior entidade junto também
	 *
	 * @param dataSource conjunto de dados para busca
	 * @return o maior valor de TO encontrado
	 * @memberof FormsService
	 */
	updateMaximumValue(dataSource: MatTableDataSource<any>): number {
		if (!dataSource.data.length) {
			return 0;
		}

		return Math.max(...dataSource.data.map((o) => o.to));
	}

	/**
	 * ### Find Errors
	 *
	 * @description Procura por campos onde a validação falhou
	 * @param {UntypedFormGroup} form o formulário que precisa ser consultado
	 * @return {*}  {string[]} uma lista com o nome dos campos que houveram erros
	 */
	findErrors(form: UntypedFormGroup): string[] {
		const errorsArray = [];

		const controls = form.controls;

		for (const name in controls) {
			if (controls[name].invalid) {
				errorsArray.push(name);
			}
		}

		return errorsArray;
	}

	private convertCsvRegisterToObject(
		register: any[],
		fields: string[],
		defaultValues = {},
	) {
		const registerAsObject: any = defaultValues;

		// Converte valores do registro para objeto
		register.forEach((value, index) => {
			const fieldName = fields[index];
			registerAsObject[fieldName] = value;
		});

		return registerAsObject;
	}

	convertNamingType(type: string) {
		switch (type) {
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				return 'xlsx';
			case 'application/pdf':
				return 'pdf';
			case 'image/jpeg':
				return 'jpeg';
			case 'image/png':
				return 'png';
			case 'image/jpg':
				return 'jpg';
			default:
				return 'generic';
		}
	}

	processCsvData(
		registers: any[],
		fields: string[],
		defaultValues: Object,
		validator: (object: any) => any[],
	): Promise<any[]> {
		return new Promise((resolve, reject) => {
			const formData: any[] = [];

			registers.forEach(async (register) => {
				if (register.length === fields.length) {
					// Converte valores do registro para objeto
					const registerAsObject = this.convertCsvRegisterToObject(
						register,
						fields,
						defaultValues,
					);

					// Verifica se há algum erro no dados do form
					const errorsArray = validator(registerAsObject);

					if (errorsArray.length) {
						return reject(errorsArray);
					}

					formData.push(registerAsObject);

				}
			});
			resolve(formData);
		});
	}

	convertCsvIntoObjects(file: any, data: any): Promise<any> {
		return new Promise((resolve, reject) => {
			parse(file, {
				complete: (result) => {
					resolve(result);
				},
				error: (error, file, inputElem, reason) => {
					reject(error);
				},
			});
		});
	}

	// TODO: get valid forms from api
	getSiteForms(siteID) {
		return this.httpClient
			.get<any>(this.apiUrl + 'field/site/' + siteID)
			.subscribe((data) => {});
	}

	getFormNamesAsArray(object: Object): string[] {
		const result: string[] = [];

		for (const [key, value] of Object.entries(object)) {
			result.push(key.toLocaleLowerCase());
		}

		return result;
	}

	getFormFieldsNames(object: any, formName: Entity | string): string[] {
		const result: string[] = [];

		for (const entry of Object.entries(object[formName])) {
			const [, value]: [string, any] = entry;

			result.push(value.field);
		}

		return result;
	}

	getNextSampleCode(collarID: string): Observable<string> {
		return this.httpClient.get<string>(`${this.apiUrl}samples/collar/${collarID}/next-code`);
	}

	/**
	 * ### Replace Foreign Characters
	 *
	 * @description Remove acentos e sinais gráficos para facilitar comparação de string
	 * @param {string} text texto original
	 * @return {string} texto com sinais removidos
	 */
	replaceForeignCharacters(text: string): string {
		return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}

	prepareForm(form: UntypedFormGroup, collarID): void {
		form.reset();

		form.controls.collarId.setValue(collarID);
	}

	getDirtyFields(form: UntypedFormGroup): any[] {
		const dirtyFields = [];

		const controls = form.controls;

		for (const name in controls) {
			if (controls[name].dirty) {
				dirtyFields.push(name);
			}
		}

		return dirtyFields;
	}

	sortBy(
		data: { from?: number; to?: number; depth?: number }[],
		parameter: 'from' | 'to' | 'depth',
	) {
		return data
			.filter((item) => item[parameter])
			.sort((a, b) => a[parameter] - b[parameter]);
	}

	/**
	 * Track by function for ngFor loops
	 *
	 * @param index
	 * @param item
	 */
	trackByFn(index: number, item: { id: string }): number | string {
		return item.id || index;
	}
}
