export const environment = {
	production: true,
	url: `https://${window.location.hostname}/api/`,
	auth: {
		url: 'https://auth.auraminerals.com',
		realm: 'camunda',
		clientId: 'angular-identity-service',
		logoutUri: `https://${window.location.hostname}/sign-out`,
	},
};
