import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PwaPluginModule } from './plugin';

@NgModule({
	imports: [
		CommonModule,
		PwaPluginModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: true,
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
})
export class PwaModule {}
