import { Inject, Injectable, Optional } from "@angular/core";
import { MAT_DATE_LOCALE, NativeDateAdapter } from "@angular/material/core";

@Injectable()
export class AdapterDate extends NativeDateAdapter{

    constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
        super(dateLocale);
      }

    format(date: Date, displayFormat: Object): string {
        date.setHours(date.getHours() + 3)

        return `${date.getDate().toString().padStart(2,"0")}/${(date.getMonth() + 1).toString().padStart(2,"0")}/${date.getFullYear()}`
    }

    parse(value: any, parseFormat?: any): Date {

        const date = new Date(value)
        date.setHours(date.getHours() + 3)

        return date

    }




}