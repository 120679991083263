import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class SwUpdateAppPlugin implements OnDestroy {
	private _subscription = new Subscription();

	constructor(private readonly swUpdate: SwUpdate) {
		this.initSwUpdate();
	}

	ngOnDestroy(): void {
		this._subscription.unsubscribe();
	}

	initSwUpdate() {
		this._subscription.add(
			this.swUpdate.versionUpdates
				.pipe(
					filter(
						(evt): evt is VersionReadyEvent =>
							evt.type === 'VERSION_READY'
					)
				)
				.subscribe(() => console.log('New app version avaialble!'))
		);
	}
}
