import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, map, merge, of } from 'rxjs';
import { SyncService } from './modules/sync/sync.service';

@Injectable({ providedIn: 'root' })
export class AppService {
	/**
	 * ### Observável que verifica se o aplicativo está online ou offline
	 *
	 * @example this.AppService.networkStatus.value // true
	 * @description o valor será **true** para online e **false** para offline
	 */
	public networkStatus = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly syncService: SyncService,
		// private readonly keycloakService: KeycloakService
	) {
		this.checkNetworkStatus();

		// if (this.networkStatus) {
		// 	this.setupRefreshToken();
		// }
	}

	checkNetworkStatus(): void {
		merge(
			of(null),
			fromEvent(window, 'online'),
			fromEvent(window, 'offline')
		)
			.pipe(map(() => navigator.onLine))
			.subscribe((s) => {
				this.networkStatus.next(s);
				this.syncService.sync(s);
			});
	}

	// setupRefreshToken(): void {
	// 	this.keycloakService.keycloakEvents$.subscribe({
	// 		next: (event) => {
	// 			switch (event.type) {
	// 				case KeycloakEventType.OnTokenExpired:
	// 					this.keycloakService.updateToken(20);
	// 					break;
	// 				case KeycloakEventType.OnAuthSuccess:
	// 					console.log('User Logged in');
	// 					break;
	// 				case KeycloakEventType.OnAuthLogout:
	// 					console.log('User Logged out');
	// 					break;
	// 				default:
	// 					break;
	// 			}
	// 		},
	// 	});
	// }
}
