import { NgModule, Optional, SkipSelf } from '@angular/core';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { AuthorizationModule } from './authorization';
import { PwaModule } from './pwa';
import { StorageModule } from './storage';

@NgModule({
	imports: [
		// AuthModule,
		AuthorizationModule,
		IconsModule,
		TranslocoCoreModule,
		StorageModule,
		PwaModule,
	],
})
export class CoreModule {
	/**
	 * Constructor
	 */
	constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
		// Do not allow multiple injections
		if (parentModule) {
			throw new Error(
				'CoreModule has already been loaded. Import this module in the AppModule only.'
			);
		}
	}
}
