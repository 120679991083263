/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Entity, Operation, PersistData, db } from 'app/shared/db/db';
import { liveQuery } from 'dexie';
import { environment } from 'environments/environment';
import { nanoid } from 'nanoid';
import { retry } from 'rxjs';

export enum Method {
	post = 'post',
	put = 'put',
	patch = 'patch',
	delete = 'delete'
}

@Injectable({ providedIn: 'root' })
export class SyncService {
	private timeoutInMS = 5000;
	private storageKey = 'syncTasks';
	private apiUrl = environment.url;

	constructor(private http: HttpClient) { }

	public async sync(status: boolean): Promise<void> {
		if (!status) { return; }
		const persistance = await db.persistance.toArray();

		await Promise.allSettled(persistance.map(async (entry) => {

			const { id, saved: body, operation, file } = entry;

			const [, entityId] = id.split(':');
			const [entity, method] = operation.split('::') as [Entity, Operation];

			if (method === Method.post && entity === 'box-and-photos') {
				const payload = new FormData();
				payload.append('image', file);
				payload.append('payload', JSON.stringify(body));
				this.http
					.post(`${this.apiUrl}${entity}`, payload)
					.pipe(retry(2))
					.subscribe({ next: async () => await db.persistance.delete(id) });
				return;
			}

			{
				if (method === Method.post) {
					this.http
						.request(method, `${this.apiUrl}${entity}`, { body })
						.pipe(retry(2))
						.subscribe({ next: async () => await db.persistance.delete(id) });
					return;
				}
			}

			if (method === Method.delete) {
				this.http
					.request(method, `${this.apiUrl}${entity}/${entityId}`)
					.pipe(retry(2))
					.subscribe({ next: async () => await db.persistance.delete(id) });
				return;
			}

			this.http
				.request(method, `${this.apiUrl}${entity}/${entityId}`, { body })
				.pipe(retry(2))
				.subscribe({ next: async () => await db.persistance.delete(id) });
		}));
	}

	/**
	 * ### Salva dados de uma entidade offline
	 *
	 * @description Processo que verifica campos do formulário para salvá-los offline
	 * @param FORM_ENTITY Formulário que contém os dados da entidade
	 * @param ENTITY_DATA Dados da entidade
	 * @param ENTITY_TYPE Tipo da entidade
	 * @return
	 */
	async offlineSaveForm(
		FORM_ENTITY: FormGroup,
		ENTITY_DATA: any,
		ENTITY_TYPE: Entity,
		API_METHOD: Operation
	): Promise<void> {
		const dirtyFields = Object.keys(FORM_ENTITY.controls)
			.map(control =>
				FORM_ENTITY.controls[control].dirty
					? { [control]: FORM_ENTITY.get(control).value }
					: undefined
			)
			.filter(Boolean)
			.reduce((prev, curr) => (Object.assign({}, prev, curr)), {});

		const id = `${ENTITY_TYPE}:${ENTITY_DATA.id}`;
		const hasEntry = await db.persistance.get(id);
		const payload: PersistData = { id, saved: dirtyFields, operation: `${ENTITY_TYPE}::${API_METHOD}` };
		if (hasEntry) {
			db.persistance.put(payload);
		} else {
			db.persistance.add(payload);
		}
	}

	/**
	 * ### Realiza um POST offline
	 *
	 * @description Processo que verifica campos do formulário para salvá-los offline
	 * @param ENTITY_FORM Formulário que contém os dados da entidade
	 * @param ENTITY_TYPE Tipo da entidade
	 * @return
	 */
	async offlinePostForm(ENTITY_FORM: FormGroup, ENTITY_TYPE: Entity): Promise<void> {
		const form = ENTITY_FORM.getRawValue();

		const id = `${ENTITY_TYPE}:${nanoid()}`;
		const hasEntry = await db.persistance.get(id);
		const payload: PersistData = { id, saved: form, operation: `${ENTITY_TYPE}::${Method.post}` };
		if (hasEntry) {
			db.persistance.put(payload);
		} else {
			db.persistance.add(payload);
		}
	}

	async offlinePostFormData(FORM_DATA: any, ENTITY_TYPE: Entity, file: File): Promise<void> {
		const data = FORM_DATA;
		const id = `${ENTITY_TYPE}:${nanoid()}`;
		const hasEntry = await db.persistance.get(id);
		const payload: PersistData = { id, saved: data, operation: `${ENTITY_TYPE}::${Method.post}`, file };
		if (hasEntry) {
			db.persistance.put(payload);
		} else {
			db.persistance.add(payload);
		}
	}

	async offlinePostEntity(ENTITY: any, ENTITY_TYPE: Entity): Promise<void> {
		const form = ENTITY;

		const id = `${ENTITY_TYPE}:${nanoid()}`;
		const hasEntry = await db.persistance.get(id);
		const payload: PersistData = { id, saved: form, operation: `${ENTITY_TYPE}::${Method.post}` };

		if (hasEntry) {
			db.persistance.put(payload);
		} else {
			db.persistance.add(payload);
		}
	}

	async offlineEditEntity(ENTITY: any, ENTITY_TYPE: Entity): Promise<void> {
		const form = ENTITY;

		const id = `${ENTITY_TYPE}:${nanoid()}`;
		const hasEntry = await db.persistance.get(id);
		const payload: PersistData = { id, saved: form, operation: `${ENTITY_TYPE}::${Method.patch}` };
		if (hasEntry) {
			db.persistance.put(payload);
		} else {
			db.persistance.add(payload);
		}
	}

	async offlineDelete(ENTITY_TYPE: Entity, entity: any): Promise<void> {

		const id = `${ENTITY_TYPE}:${entity.id}`;
		const hasEntry = await db.persistance.get(id);
		const payload: PersistData = { id, saved: entity, operation: `${ENTITY_TYPE}::${Method.delete}` };
		if (hasEntry) {
			db.persistance.put(payload);
		} else {
			db.persistance.add(payload);
		}
	}

	/**
	 * ### Sobrepõe dados locais com dados offline
	 *
	 * @description Processo que verifica se existe dados salvos offline para a entidade e sobrepõe os dados locais
	 * @param entity Dados da entidade
	 * @param ENTITY_TYPE Tipo da entidade
	 */
	async overlapOffline(entity: any, ENTITY_TYPE: Entity): Promise<void> {

		liveQuery(() => db.persistance.get(`${ENTITY_TYPE}:${entity.id}`))
			.subscribe((data) => {
				if (!data) { return; }
				entity = Object.assign({}, entity, data.saved);
			});
	}

	/**
	 * ### Sobrepõe dados locais do formulário com dados offline
	 *
	 * @description Processo que verifica se existe dados salvos offline para a entidade e sobrepõe os dados locais
	 * @param FORM_ENTITY Formulário que contém os dados da entidade
	 * @param ENTITY_DATA Dados da entidade
	 * @param ENTITY_TYPE Tipo da entidade
	 */
	async overlapOfflineForm(FORM_ENTITY: FormGroup, ENTITY_DATA: any, ENTITY_TYPE: Entity): Promise<void> {

		liveQuery(() => db.persistance.get(`${ENTITY_TYPE}:${ENTITY_DATA.id}`))
			.subscribe((data) => {
				if (!data) { return; }
				FORM_ENTITY.patchValue(data.saved);
				Object.keys(data.saved).forEach(field => FORM_ENTITY.controls[field].markAsDirty());
			});
	}

	/**
	 * ### Sobrepõe dados locais com dados offline de um array
	 *
	 * @description Processo que verifica se existe dados salvos offline para a entidade e sobrepõe os dados locais
	 * @param entity_array Dados da entidade
	 * @param ENTITY_TYPE Tipo da entidade
	 */
	async overlapOfflineArray(entity_array: any[], ENTITY_TYPE: Entity): Promise<void> {

		entity_array.forEach((entity) => {
			this.overlapOffline(entity, ENTITY_TYPE);
		});
	}

	async logEntities(entity: Entity): Promise<void> {
		await db.persistance.where('id').startsWith(entity).toArray()
			.then(data => console.log('entitiesDB', data));
	}

	async getOfflineEntities(entity: Entity): Promise<any[]> {

		return await db.persistance.where('id').startsWith(entity).toArray()
			.then(data => data.map(entry => entry.saved));
	}
}
