import { TTLManager } from '@ngneat/cashew';


export class ExplorationTTLManager extends TTLManager {
	isValid(key: string): boolean {
		if (!window.navigator.onLine)  {
			return true;
		}
		return this.get(key) > new Date().getTime();
	}
}
