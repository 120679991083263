import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'app/core/storage';
import { environment } from 'environments/environment';
import { KeycloakConfig, KeycloakProfile } from 'keycloak-js';
import { tap } from 'rxjs/operators';

@Injectable()
export class OfflineAuthService {
	private _headers = new HttpHeaders({
		'Content-Type': 'application/x-www-form-urlencoded',
		'Accept': '*/*',
	});

	constructor(
		private readonly storageService: StorageService,
		private readonly router: Router,
		private readonly http: HttpClient
	) {}

	get isLoggedIn(): boolean {
		return !!this.storageService.userInfo && !!this.storageService.token;
	}

	get userInfo(): KeycloakProfile {
		return this.storageService.userInfo;
	}

	get token(): string {
		return this.storageService.token;
	}

	updateToken() {
		const { url, realm, clientId } = environment.auth as KeycloakConfig;
		return this.http
			.post<any>(
				`${url}/realms/${realm}/protocol/openid-connect/token`,
				new URLSearchParams({
					client_id: clientId,
					grant_type: 'refresh_token',
					refresh_token: this.storageService.refreshToken,
				}),
				{ headers: this._headers }
			)
			.pipe(
				tap(({ access_token, refresh_token }) =>
					this.storageService.updateToken({
						token: access_token,
						refreshToken: refresh_token,
					})
				)
			);
	}
}
