<div class="w-full max-w-3xl flex flex-col mr-10 gap-4 flex-auto px-8 pt-9 pb-12">
	<div class="flex flex-col">

		<a class="inline-flex items-center leading-6 cursor-pointer text-accent"
			[routerLink]="['/apps/sites']">
			<span class="inline-flex items-center">
				<mat-icon class="icon-size-5 text-current"
					[svgIcon]="'heroicons_solid:arrow-sm-left'"></mat-icon>
					<span class="ml-1.5 font-medium leading-5">back</span>
			</span>
		</a>

		<span class="flex gap-2 flex-wrap mt-4 text-3xl tracking-tight leading-none">
			Register a new site
		</span>
	</div>

	<form [formGroup]="newSiteForm">

		<div class="flex flex-col gap-4 mt-8">

			<mat-form-field class="w-full">
				<mat-label>Site name</mat-label>
				<input matInput formControlName="siteName"/>
			</mat-form-field>

		</div>

		<div class="flex justify-end">
			<button mat-flat-button [color]="'primary'" (click)="sendForm()">
				Salvar
			</button>

		</div>

	</form>

</div>

