export const user = [
	{
		id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
		name: 'admin',
		email: 'admin@auraminerals.com',
		password: 'adminaura',
		status: 'online',
		sites: [{ id: 'ae9926c1-3827-4e15-8cf6-ca915a3036cc' }, { id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5dd' }],
		formOptions: [
			{
				visible: true,
				entity: 'boxandphotos',
				validFields: ['from', 'to', 'box', 'comments',]
			},
			{
				visible: true,
				entity: 'collar',
				validFields: [
					'holeName', 'status', 'drillHolePurpose', 'x', 'y', 'z', 'depth', 'coordinateSystem', 'xyzBy',
					'xyzDate', 'startDate', 'endDate', 'drillHoleOperateCompany', 'collarInstrument', 'section',
					'drillHoleYear', 'drillingCompany', 'dhSurveyCompany', 'domain', 'miningProcess', 'comments'
				]
			},
			{
				visible: true,
				entity: 'density',
				validFields: [
					'from', 'to', 'box', 'wetWeightG', 'initialWaterLevelMl', 'finalWaterLevelMl', 'sampleVolumeMl',
					'initialWaterLevelCm', 'finalWaterLevelCm', 'sampleVolumeCm3', 'densityGcm3', 'loggedBy',
					'loggedDate', 'comments', 'wetWeightAirG', 'weightDryG', 'weightWater', 'moist', 'method', 'length'
				]
			},
			{
				visible: true,
				entity: 'drillholeProgram',
				validFields: [
					'padId', 'plannedAzimuth', 'plannedDip', 'plannedX', 'plannedY', 'plannedZ', 'plannedDepth', 'from',
					'drillHoleType'
				]
			},
			{
				visible: true,
				entity: 'drillreport',
				validFields: ['from', 'to', 'recovery', 'diameter', 'date', 'advance', 'drillRig', 'shift', 'comments']
			},
			{
				visible: true,
				entity: 'geotech',
				validFields: [
					'from', 'to', 'recovery', 'sumNumPiecesGt10cmMeter', 'rockAlteration', 'resistanceR',
					'resistanceCoefficient', 'spacingF', 'spacingCoefficient', 'fill', 'alteration', 'rough', 'h2o',
					'srf', 'rqd', 'coefRqd', 'rmr', 'jw', 'jr', 'ja', 'jn', 'q', 'q2', 'zone', 'tcr', 'coefTcr',
					'rqdMeters', 'lengthIrs', 'weathering', 'brokenMeters', 'rubbleMeters', 'loggedBy', 'loggedDate',
					'comments'
				]
			},
			{
				visible: true,
				entity: 'lithology',
				validFields: [
					'from', 'to', 'lithology', 'chloritization', 'calcitization', 'silicification', 'sericitization',
					'quartzVein', 'magnetite', 'specularite', 'goethite', 'goldNuggets', 'clay', 'hematite', 'asPy',
					'sulfides', 'sulfidesB', 'oreType', 'lithologyDescription', 'loggedBy', 'loggedDate', 'colour11',
					'colour11Intensity', 'colour21', 'colour21Intensity', 'secondary', 'colour12', 'colour12Intensity',
					'colour22', 'colour22Intensity', 'material', 'oxSul', 'argilyc', 'prophilityc', 'jarGeo', 'pyrite',
					'structure', 'dryWet', 'garnet', 'garnetColor', 'pyroxene', 'pyroxeneColor', 'pyroxeneColorIntst',
					'garnetPyroxene', 'stockwork', 'diss', 'vein', 'veinlets', 'massive', 'oxidation', 'potassic',
					'progradeRetrograde', 'brecciaZone', 'fractureZone', 'failZone', 'skarnZone', 'epidote',
					'amphibole', 'hornblend', 'malachite', 'azurite', 'chrysocolla', 'cuprite', 'limonite',
					'manganeseOxide', 'pyrrhotite', 'chalcopyrite', 'tennantite', 'sphalerite', 'galena', 'molybdenite',
					'stibnite', 'enargite', 'bornite', 'chalcocite', 'covellite', 'feldspar', 'biotite', 'muscovite',
					'tourmaline', 'wollastonite', 'comments', 'pyrite', 'carbonation', 'albite', 'granate',
					'actinolite', 'ankerite', 'barite', 'fluorite', 'titanite', 'boxWork', 'manganese',
					'potassicFeldspar', 'descriptionType', 'texture', 'grainSize', 'alterationZone', 'intensity',
					'alterationStyle', 'weathering'
				]
			},
			{
				visible: true,
				entity: 'qtzvein',
				validFields: [
					'from', 'to', 'dip', 'azimuth', 'quartzVeinRelationshipWithTheHostRock', 'tchickness', 'magnetite',
					'geothite', 'specularite', 'goldNuggets', 'clay', 'hematite', 'sulfides', 'asPy', 'loggedBy',
					'loggedDate', 'comments'
				]
			},
			{
				visible: true,
				entity: 'structure',
				validFields: [
					'from', 'to', 'structure', 'dip', 'azimuth', 'loggedBy', 'loggedDate', 'comments', 'dipDirection',
					'fill1', 'fill2', 'fill3', 'fill4', 'alfaAngle', 'betaAngle'
				]
			},
			{
				visible: true,
				entity: 'survey',
				validFields: [
					'depth', 'dip', 'azimuth', 'surveyEquipament', 'surveyMode', 'surveyBy', 'surveyDate', 'comments'
				]
			},
			{
				visible: true,
				entity: 'samples',
				validFields: ['*']
			},
			{
				visible: true,
				entity: 'despatch',
				validFields: ['*']
			},
			{
				visible: true,
				entity: 'magnetic-suscepbility',
				validFields: ['from', 'to', 'kappaMedium', 'responsable']
			},
			{
				visible: true,
				entity: 'mineralization',
				validFields: [
					'from', 'to', 'mineralization1', 'texture1', 'oreIntensity1', 'mineralization2', 'texture2',
					'oreIntensity2', 'mineralization3', 'texture3', 'oreIntensity3', 'mineralization4', 'texture4',
					'oreIntensity4', 'mineralization5', 'texture5', 'oreIntensity5'
				]
			}
		],
		lithos: [
			'SSCH', 'MAR', 'MGL', 'FMAR', 'MYL', 'MTNL', 'CMAR', 'SOIL', 'SAP', 'SQSCH', 'QZV', 'MPEL', 'BRXX', 'MBLT',
			'GRA', 'DBS', 'NR', 'MRIT', 'SCH', 'GRAN', 'BSCH', 'MSIL', 'AND', 'NR', 'SOIL', 'SAP', 'SSCH', 'MPEL',
			'MAR', 'MGL', 'FMAR', 'MYL', 'QZV', 'MTNL', 'MBLT', 'SCH', 'BRXX', 'SQSCH', 'BSCH', 'CMAR', 'GRA', 'DBS',
			'MSIL', 'MRIT'
		],
		userList: [
			{ id: '87ed2394-fe8a-416d-a4e1-cb8df111a6bf', name: 'Adryan' },
			{ id: '44736a14-59f3-4a50-a1df-afd246c9b0cf', name: 'Bruno' },
			{ id: '0aa94cc8-5f38-41c2-b5a8-6534eeb3efc0', name: 'Caio' },
			{ id: '59166283-8ab3-4f67-8680-4605a06e6cc2', name: 'Deyvson' },
			{ id: '71daaa5e-47fd-4a4d-8cd9-fd6b9ce0776c', name: 'Eric' },
			{ id: '60496907-b607-402c-b289-1588ada80b0b', name: 'Henrique' },
			{ id: '9e58e348-2b8d-4c1b-b319-0c0c25e4c2e3', name: 'João' },
			{ id: '62e93023-2995-45f5-b0ec-d7844530791d', name: 'Marcelo' },
		]
	}, {
		id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
		name: 'Apoena',
		email: 'apoena@auraminerals.com',
		password: 'apoena',
		status: 'online',
		sites: [{ id: 'ae9926c1-3827-4e15-8cf6-ca915a3036cc' }],
		formOptions: [
			{
				visible: true,
				entity: 'boxandphotos',
				validFields: ['from', 'to', 'box', 'comments',]
			},
			{
				visible: true,
				entity: 'collar',
				validFields: [
					'holeName', 'status', 'drillHolePurpose', 'x', 'y', 'z', 'depth', 'startDate', 'endDate',
					'collarInstrument', 'section', 'drillHoleYear', 'drillingCompany', 'dhSurveyCompany', 'comments'
				]
			},
			{
				visible: true,
				entity: 'density',
				validFields: [
					'from', 'to', 'wetWeightG', 'initialWaterLevelMl', 'finalWaterLevelMl', 'sampleVolumeMl',
					'densityGcm3', 'loggedDate', 'loggedBy', 'comments'
				]
			},
			{
				visible: true,
				entity: 'drillholeProgram',
				validFields: ['padId', 'plannedAzimuth', 'plannedDip', 'plannedX', 'plannedY', 'plannedZ',
					'plannedDepth'
				]
			},
			{
				visible: true,
				entity: 'drillreport',
				validFields: ['from', 'to', 'diameter', 'date', 'advance', 'drillRig', 'shift', 'comments']
			},
			{
				visible: true,
				entity: 'geotech',
				validFields: [
					'from', 'to', 'recovery', 'sumNumPiecesGt10cmMeter', 'rockAlteration', 'resistanceR',
					'resistanceCoefficient', 'spacingF', 'spacingCoefficient', 'fill', 'alteration', 'rough', 'h2o',
					'srf', 'rqd', 'coefRqd', 'rmr', 'jw', 'jr', 'ja', 'jn', 'q', 'q2', 'loggedBy', 'loggedDate',
					'comments'
				]
			},
			{
				visible: true,
				entity: 'lithology',
				validFields: [
					'from', 'to', 'lithology', 'chloritization', 'calcitization', 'silicification', 'sericitization',
					'quartzVein', 'magnetite', 'specularite', 'goethite', 'goldNuggets', 'clay', 'hematite', 'asPy',
					'sulfides', 'oreType', 'lithologyDescription', 'loggedBy', 'loggedDate', 'comments'
				]
			},
			{
				visible: true,
				entity: 'qtzvein',
				validFields: [
					'from', 'to', 'dip', 'azimuth', 'quartzVeinRelationshipWithTheHostRock', 'tchickness', 'magnetite',
					'geothite', 'specularite', 'goldNuggets', 'clay', 'hematite', 'sulfides', 'asPy', 'loggedBy',
					'loggedDate', 'comments'
				]
			},
			{
				visible: true,
				entity: 'structure',
				validFields: ['from', 'to', 'structure', 'dip', 'azimuth', 'loggedBy', 'loggedDate', 'comments']
			},
			{
				visible: true,
				entity: 'survey',
				validFields: ['depth', 'dip', 'azimuth', 'surveyEquipament', 'comments']
			},
			{
				visible: false,
				entity: 'magnetic-suscepbility',
				validFields: ['from', 'to', 'kappaMedium', 'responsable']
			},
			{
				visible: false,
				entity: 'mineralization',
				validFields: [
					'from', 'to', 'mineralization1', 'texture1', 'oreIntensity1', 'mineralization2', 'texture2',
					'oreIntensity2', 'mineralization3', 'texture3', 'oreIntensity3', 'mineralization4', 'texture4',
					'oreIntensity4', 'mineralization5', 'texture5', 'oreIntensity5',
				]
			},
			{
				visible: true,
				entity: 'samples',
				validFields: ['*']
			},
			{
				visible: true,
				entity: 'despatch',
				validFields: ['*']
			}
		],
		lithos: [
			'SSCH', 'MAR', 'MGL', 'FMAR', 'MYL', 'MTNL', 'CMAR', 'SOIL', 'SAP', 'SQSCH', 'QZV', 'MPEL', 'BRXX', 'MBLT',
			'GRA', 'DBS', 'NR', 'MRIT', 'SCH', 'GRAN', 'BSCH', 'MSIL', 'AND', 'NR', 'SOIL', 'SAP', 'SSCH', 'MPEL',
			'MAR', 'MGL', 'FMAR', 'MYL', 'QZV', 'MTNL', 'MBLT', 'SCH', 'BRXX', 'SQSCH', 'BSCH', 'CMAR', 'GRA', 'DBS',
			'MSIL', 'MRIT'
		],
		userList: [
			{ id: 'd95158aa-2b03-4ffd-a674-5af63b6ff02a', role: 'Geologist', name: 'Leonardo Manara' },
			{ id: 'a39958b8-eed2-4b80-b964-0ed5c6dd7d09', role: 'Geologist', name: 'Mariana Benedett' },
			{ id: 'ca956fb9-ab7d-4743-a167-b6fe9043af5b', role: 'Geologist', name: 'Jorge Echague' },
			{ id: '8860ffdd-096b-4f96-8bd2-70b04193b345', role: 'Geologist', name: 'Amanda Freire' },
			{ id: '11e59164-21d0-42fd-b2e2-a2c58038c1dc', role: 'Geologist', name: 'Caio Teixeira' },
		]
	}, {
		id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5dd',
		name: 'Minosa',
		email: 'minosa@auraminerals.com',
		password: 'minosa',
		status: 'online',
		sites: [{ id: 'e734ceea-6cca-439e-9d76-242725f4325f' }],
		formOptions: [
			{
				visible: false,
				entity: 'boxandphotos',
				validFields: ['from', 'to', 'box', 'comments',]
			},
			{
				visible: true,
				entity: 'collar',
				validFields: [
					'holeName', 'status', 'x', 'y', 'z', 'depth', 'coordinateSystem', 'xyzDate', 'startDate', 'endDate',
					'collarInstrument', 'miningProcess', 'comments'
				]
			},
			{
				visible: false,
				entity: 'density',
				validFields: [
					'from', 'to', 'box', 'wetWeightG', 'initialWaterLevelMl', 'sampleVolumeMl', 'initialWaterLevelCm',
					'finalWaterLevelCm', 'sampleVolumeCm3', 'densityGcm3', 'loggedBy', 'loggedDate', 'comments'
				]
			},
			{
				visible: true,
				entity: 'drillholeProgram',
				validFields: [
					'padId', 'plannedAzimuth', 'plannedDip', 'plannedX', 'plannedY', 'plannedZ', 'plannedDepth'
				]
			},
			{
				visible: true,
				entity: 'drillreport',
				validFields: ['from', 'to', 'date', 'advance', 'drillRig', 'shift', 'comments']
			},
			{
				visible: false,
				entity: 'geotech',
				validFields: [
					'from', 'to', 'recovery', 'sumNumPiecesGt10cmMeter', 'rockAlteration', 'resistanceR',
					'resistanceCoefficient', 'spacingF', 'spacingCoefficient', 'fill', 'alteration', 'rough', 'h2o',
					'srf', 'rqd', 'coefRqd', 'rmr', 'jw', 'jr', 'ja', 'jn', 'q', 'q2', 'zone', 'tcr', 'coefTcr',
					'rqdMeters', 'lengthIrs', 'weathering', 'brokenMeters', 'rubbleMeters', 'loggedBy', 'loggedDate',
					'comments'
				]
			},
			{
				visible: true,
				entity: 'lithology',
				validFields: [
					'from', 'to', 'lithology', 'silicification', 'quartzVein', 'hematite', 'loggedBy', 'loggedDate',
					'colour11', 'colour11Intensity', 'colour21', 'colour21Intensity', 'secondary', 'colour12',
					'colour12Intensity', 'colour22', 'colour22Intensity', 'material', 'oxSul', 'argilyc', 'prophilityc',
					'jarGeo', 'pyrite', 'structure', 'dryWet', 'comments'
				]
			},
			{
				visible: false,
				entity: 'qtzvein',
				validFields: [
					'from', 'to', 'dip', 'azimuth', 'quartzVeinRelationshipWithTheHostRock', 'tchickness', 'magnetite',
					'geothite', 'specularite', 'goldNuggets', 'clay', 'hematite', 'sulfides', 'asPy', 'loggedBy',
					'loggedDate', 'comments'
				]
			},
			{
				visible: false,
				entity: 'structure',
				validFields: ['from', 'to', 'structure', 'dip', 'azimuth', 'loggedBy', 'loggedDate', 'comments']
			},
			{
				visible: true,
				entity: 'survey',
				validFields: ['depth', 'dip', 'azimuth', 'surveyEquipament', 'comments',]
			},
			{
				visible: true,
				entity: 'samples',
				validFields: ['*']
			},
			{
				visible: true,
				entity: 'despatch',
				validFields: ['*']
			}
		],
		lithos: [
			'PBX', 'RHYT', 'ANT', 'NR', 'PHL', 'SOIL', 'NR', 'SOIL', 'RHYT', 'PCGL', 'QRHY', 'ANT', 'DAC', 'PHL', 'RBE',
			'SILC', 'PBX', 'IGM'
		]
		, userList: [
			{ id: 'c636e274-801e-4739-b3a0-b539491d8269', name: 'Emilson Arturo Moncada' },
			{ id: 'dbdf654b-d8a4-479a-980f-229bd303ea49', name: 'Freddy Rene Doblado' },
			{ id: '1535af74-0fc1-4750-b4de-4539ac6a6c4a', name: 'Kenneth Montero Suarez' },
			{ id: 'de4b3623-af28-490f-9aa5-dd35a014ea21', name: 'Luis Antonio Araya Monge' },
			{ id: 'deb80a4d-32ad-4374-affa-dec2b07078af', name: 'Nancy Michell Baca' },
		]
	}, {
		id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5de',
		name: 'Aranzazu',
		email: 'aranzazu@auraminerals.com',
		password: 'aranzazu',
		status: 'online',
		sites: [{ id: '406603ff-7b52-496b-8c1e-1a441a2cfbbb' }],
		formOptions: [
			{
				visible: true,
				entity: 'boxandphotos',
				validFields: ['from', 'to', 'comments',]
			},
			{
				visible: true,
				entity: 'collar',
				validFields: [
					'holeName', 'status', 'x', 'y', 'z', 'depth', 'xyzDate', 'startDate', 'endDate', 'section',
					'comments'
				]
			},
			{
				visible: true,
				entity: 'density',
				validFields: ['from', 'to', 'loggedBy', 'loggedDate', 'comments']
			},
			{
				visible: true,
				entity: 'drillholeProgram',
				validFields: [
					'padId', 'plannedAzimuth', 'plannedDip', 'plannedX', 'plannedY', 'plannedZ', 'plannedDepth'
				]
			},
			{
				visible: true,
				entity: 'drillreport',
				validFields: ['from', 'to', 'diameter', 'date', 'advance', 'drillRig', 'shift', 'comments']
			},
			{
				visible: true,
				entity: 'geotech',
				validFields: [
					'from', 'to', 'sumNumPiecesGt10cmMeter', 'resistanceR', 'rqd', 'coefRqd', 'jr', 'ja', 'jn', 'zone',
					'tcr', 'coefTcr', 'rqdMeters', 'lengthIrs', 'weathering', 'brokenMeters', 'rubbleMeters',
					'loggedBy', 'loggedDate', 'comments'
				]
			},
			{
				visible: true,
				entity: 'lithology',
				validFields: [
					'from', 'to',
					'lithology', 'chloritization', 'calcitization', 'silicification', 'sericitization', 'quartzVein',
					'magnetite', 'specularite', 'goethite', 'clay', 'hematite', 'asPy', 'loggedBy', 'loggedDate',
					'argilyc', 'prophilityc', 'pyrite', 'garnet', 'garnetColor', 'pyroxene', 'pyroxeneColor',
					'pyroxeneColorIntst', 'garnetPyroxene', 'stockwork', 'diss', 'vein', 'veinlets', 'massive',
					'oxidation', 'potassic', 'progradeRetrograde', 'brecciaZone', 'fractureZone', 'failZone',
					'skarnZone', 'epidote', 'amphibole', 'hornblend', 'malachite', 'azurite', 'chrysocolla', 'cuprite',
					'limonite', 'manganeseOxide', 'pyrrhotite', 'chalcopyrite', 'tennantite', 'sphalerite', 'galena',
					'molybdenite', 'stibnite', 'enargite', 'bornite', 'chalcocite', 'covellite', 'feldspar', 'biotite',
					'muscovite', 'tourmaline', 'wollastonite', 'comments'
				]
			},
			{
				visible: false,
				entity: 'qtzvein',
				validFields: [
					'from', 'to', 'dip', 'azimuth', 'quartzVeinRelationshipWithTheHostRock', 'tchickness',
					'magnetite', 'geothite', 'specularite', 'goldNuggets', 'clay', 'hematite', 'sulfides', 'asPy',
					'loggedBy', 'loggedDate', 'comments'
				]
			},
			{
				visible: true,
				entity: 'structure',
				validFields: ['from', 'to', 'structure', 'dip', 'azimuth', 'loggedBy', 'loggedDate', 'comments']
			},
			{
				visible: true,
				entity: 'survey',
				validFields: [
					'depth', 'dip', 'azimuth', 'surveyEquipament', 'surveyMode', 'surveyBy', 'surveyDate', 'comments'
				]
			},
			{
				visible: true,
				entity: 'samples',
				validFields: ['*']
			},
			{
				visible: true,
				entity: 'despatch',
				validFields: ['*']
			}
		],
		lithos: [
			'ESK', 'PGD', 'XSK', 'MBL', 'BRXX', 'HFL', 'VETA', 'GPP', 'SKO', 'LMS', 'CGP', 'INFL', 'PDG', 'LMS-LU',
			'css', 'BRXX', 'CSS', 'ESK', 'GPP', 'HFL', 'INFL', 'LMS', 'MBL', 'PBQA', 'PGD', 'PPBF', 'SKN', 'SKO', 'XSK',
			'NR'
		],
		userList: [
			{ id: '3c12286d-e143-42fd-99db-71e34e0fdf1c', role: 'Geologist', name: 'Augusto Sandoval' },
			{ id: '00d4f34a-5c9b-44f4-9666-ac6cabe3322d', role: 'Geologist', name: 'Citlalic Contreras' },
			{ id: 'e23bb65f-2788-4240-a5f8-3421e4baf450', role: 'Geologist', name: 'Gerardo Rivas' },
			{ id: 'e3c3a0f6-643b-4abd-8b2c-6a5194a000b4', role: 'Geologist', name: 'José Ruiz' },
			{ id: 'efdf55c7-4581-4639-9a17-23ad9bdfc71a', role: 'Database', name: 'Lorena Samperio' },
			{ id: '4049d39b-9d2d-4999-962c-2c3056e88f41', role: 'Geologist', name: 'Martin Gutiérrez' },
			{ id: '33a1e8d0-793f-4eb8-9ef1-58dd68853e83', role: 'Geologist', name: 'Natziri Ramirez' },
			{ id: '6807c6fb-8a86-454f-af1d-670d86e45d62', role: 'Geologist', name: 'Oscar Solis de la Cruz' },
			{ id: 'b528a0fa-82b8-4685-aac6-13422416a045', role: 'Geologist', name: 'Ruth Trujillo' },
		]
	}, {
		id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5fd',
		name: 'Almas',
		email: 'almas@auraminerals.com',
		password: 'almas',
		status: 'online',
		sites: [{ id: 'e5dad69e-0336-49ec-a486-9defdae6b0bf' }],
		formOptions: [
			{
				visible: true,
				entity: 'boxandphotos',
				validFields: ['from', 'to', 'box', 'comments',]
			},
			{
				visible: true,
				entity: 'collar',
				validFields: [
					'holeName', 'status', 'x', 'y', 'z', 'depth', 'startDate', 'endDate', 'collarInstrument', 'section',
					'drillHoleYear', 'drillingCompany', 'dhSurveyCompany', 'comments'
				]
			},
			{
				visible: true,
				entity: 'density',
				validFields: [
					'from', 'to', 'wetWeightG', 'initialWaterLevelCm', 'finalWaterLevelCm', 'sampleVolumeCm3',
					'densityGcm3', 'loggedDate', 'comments'
				]
			},
			{
				visible: true,
				entity: 'drillholeProgram',
				validFields: [
					'padId', 'plannedAzimuth', 'plannedDip', 'plannedX', 'plannedY', 'plannedZ', 'plannedDepth'
				]
			},
			{
				visible: true,
				entity: 'drillreport',
				validFields: ['from', 'to', 'diameter', 'date', 'advance', 'shift', 'comments']
			},
			{
				visible: true,
				entity: 'geotech',
				validFields: [
					'from', 'to',
					'recovery', 'sumNumPiecesGt10cmMeter', 'rockAlteration', 'resistanceR', 'resistanceCoefficient',
					'spacingF', 'spacingCoefficient', 'fill', 'alteration', 'rough', 'h2o', 'srf', 'rqd', 'coefRqd',
					'rmr', 'jw', 'jr', 'ja', 'jn', 'q', 'q2', 'loggedBy', 'loggedDate', 'comments'
				]
			},
			{
				visible: true,
				entity: 'lithology',
				validFields: [
					'from', 'to',
					'lithology', 'chloritization', 'silicification', 'sericitization', 'quartzVein', 'magnetite',
					'asPy', 'sulfidesB', 'lithologyDescription', 'loggedBy', 'loggedDate', 'comments', 'boxWork',
					'carbonation', 'hematite', 'epidote', 'albite', 'biotite', 'granate', 'potassicFeldspar',
					'actinolite', 'bornite', 'ankerite', 'limonite', 'manganese', 'tourmaline', 'molybdenite', 'pyrite',
					'chalcopyrite', 'pyrrhotite'
				]
			},
			{
				visible: true,
				entity: 'qtzvein',
				validFields: [
					'from', 'to', 'dip', 'azimuth', 'quartzVeinRelationshipWithTheHostRock', 'tchickness', 'magnetite',
					'geothite', 'specularite', 'goldNuggets', 'clay', 'hematite', 'sulfides', 'asPy', 'loggedBy',
					'loggedDate', 'comments'
				]
			},
			{
				visible: true,
				entity: 'structure',
				validFields: ['from', 'to', 'structure', 'dip', 'azimuth', 'loggedBy', 'loggedDate', 'comments']
			},
			{
				visible: true,
				entity: 'survey',
				validFields: ['depth', 'dip', 'azimuth', 'comments']
			},
			{
				visible: true,
				entity: 'samples',
				validFields: ['*']
			},
			{
				visible: true,
				entity: 'despatch',
				validFields: ['*']
			}
		],
		lithos: [
			'FSQ', 'CAS', 'MCH', 'MR', 'QCCX', 'FS', 'SO', 'RSI', 'SP', 'SQX', 'RI', 'MV', 'MVC', 'VQZT', 'MRc', 'GBM',
			'DT', 'GB', 'MD', 'CAQX', 'GDM', 'GDT', 'GDU', 'PGT', 'QACX', 'QSCX', 'CSQX', 'QDCX', 'MDM', 'QZT', 'FC',
			'CDQX', 'SDQX', 'SDCX', 'VQTZ', 'CDCX', 'SQCX', 'BQZ', 'GBS', 'CG', 'VQZ', 'NR', 'ADQX', 'BQZ', 'CAAX',
			'CASQX', 'CCQX', 'CCSX', 'CCX', 'CQCX', 'CQSCX', 'CQSX', 'CQX', 'CSQX', 'CX', 'DCQX', 'HTM', 'MD', 'QCASX',
			'QCCX', 'QCX', 'QFX', 'QSCAX', 'QSCX', 'QSX', 'QX', 'RI', 'RM', 'SCCAX', 'SCDX', 'SCX', 'SDCX', 'SDQX',
			'SO', 'SP', 'SQCX', 'VQTZ', 'GDM', 'MV', 'CSX', 'GQX', 'SX', 'SBX', 'RSI', 'AFB', 'QSXn', 'CQSXp', 'QSCXb',
			'DT', 'RSI', 'QTZ', 'FF', 'TUQ', 'MPc', 'MRc', 'MRpp', 'QTZmc', 'MP', 'GDT', 'PGT', 'SAQM', 'CAS', 'CPQSX',
			'CSQCX', 'FC', 'FS', 'FSQ', 'MCG', 'MCH', 'MDI', 'QZT'
		],
		userList: [
			{ id: '5ff77df8-0cce-43a2-98c2-fa4a458be5dc', role: 'Geologist', name: 'Augusto Fonseca' },
			{ id: '401aa4c1-b1e6-40d7-8c5e-c9f4bc574aa3', role: 'Geologist', name: 'Daniel Chaves' },
			{ id: 'b71f8fb1-13dd-4aa7-a6b6-c278a635975e', role: 'Geologist', name: 'Paulo Lima' },
			{ id: '14c4b052-7cd7-44cb-9922-6e348e250678', role: 'Mining Technician', name: 'Geraldo Andrade' }
		]
	}, {
		id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5cd',
		name: 'Matupá',
		email: 'matupa@auraminerals.com',
		password: 'matupa',
		//avatar: 'assets/images/avatars/matupa.png',
		status: 'online',
		sites: [{ id: 'af064d45-30a7-49a7-aa09-fc0efe2962e7' }],
		formOptions: [
			{
				visible: true,
				entity: 'boxandphotos',
				validFields: ['from', 'to', 'box', 'comments',]
			},
			{
				visible: true,
				entity: 'collar',
				validFields: [
					'holeName', 'status', 'x', 'y', 'z', 'depth', 'startDate', 'endDate', 'drillHoleYear', 'comments'
				]
			},
			{
				visible: true,
				entity: 'density',
				validFields: [
					'from', 'to', 'densityGcm3', 'loggedDate', 'comments', 'wetWeightAirG', 'weightDryG', 'weightWater',
					'moist', 'method', 'length', 'loggedBy'
				]
			},
			{
				visible: true,
				entity: 'drillholeProgram',
				validFields: [
					'padId', 'plannedAzimuth', 'plannedDip', 'plannedX', 'plannedY', 'plannedZ', 'plannedDepth'
				]
			},
			{
				visible: true,
				entity: 'drillreport',
				validFields: ['from', 'to', 'diameter', 'date', 'advance', 'shift', 'comments']
			},
			{
				visible: true,
				entity: 'geotech',
				validFields: [
					'from', 'to', 'recovery', 'sumNumPiecesGt10cmMeter', 'fill', 'rough', 'jn', 'rockAlteration',
					'resistanceR', 'spacingF', 'alteration', 'loggedBy', 'loggedDate', 'comments'
				]
			},
			{
				visible: true,
				entity: 'lithology',
				validFields: [
					'from', 'to',
					'lithology', 'chloritization', 'silicification', 'sericitization', 'magnetite', 'hematite',
					'lithologyDescription', 'loggedBy', 'texture', 'grainSize', 'alterationZone', 'intensity',
					'alterationStyle', 'weathering', 'loggedDate', 'pyrite', 'epidote', 'limonite', 'biotite',
					'comments', 'carbonation', 'albite', 'granate', 'actinolite', 'ankerite', 'barite', 'fluorite',
					'titanite', 'boxWork', 'manganese', 'potassicFeldspar', 'descriptionType'
				]
			},
			{
				visible: false,
				entity: 'qtzvein',
				validFields: [
					'from', 'to', 'dip', 'azimuth', 'quartzVeinRelationshipWithTheHostRock', 'tchickness', 'magnetite',
					'geothite', 'specularite', 'goldNuggets', 'clay', 'hematite', 'sulfides', 'asPy', 'loggedBy',
					'loggedDate', 'comments'
				]
			},
			{
				visible: true,
				entity: 'structure',
				validFields: [
					'from', 'to', 'structure', 'dip', 'azimuth', 'loggedBy', 'loggedDate', 'comments', 'dipDirection',
					'fill1', 'fill2', 'fill3', 'fill4', 'alfaAngle', 'betaAngle'
				]
			},
			{
				visible: true,
				entity: 'survey',
				validFields: ['depth', 'dip', 'azimuth', 'surveyEquipament', 'comments', 'surveyMethod']
			},
			{
				visible: true,
				entity: 'samples',
				validFields: ['*']
			},
			{
				visible: true,
				entity: 'despatch',
				validFields: ['*']
			},
			{
				visible: true,
				entity: 'magnetic-suscepbility',
				validFields: ['from', 'to', 'kappaMedium', 'responsable']
			},
			{
				visible: true,
				entity: 'mineralization',
				validFields: ['*']
			}
		],
		lithos: [
			'QMZ', 'IVR', 'GRD', 'PEG', 'MGR', 'SO', 'SAP', 'AP', 'GRN', 'AVR', 'WR', 'SCH', 'MING', 'DIO', 'QZV',
			'FTZ', 'HBR', 'ATERRO', 'DAC', 'W1', 'W2', 'GN', 'QFP', 'RH', 'ALV', 'AD', 'AP', 'AR', 'BS', 'BR', 'HBR',
			'DAC', 'DB', 'DIO', 'GB', 'GN', 'GRD', 'MING', 'MGR', 'PEG', 'QFP', 'QMZ', 'RH', 'WR', 'AVR', 'IVR', 'SAP',
			'SY', 'SGR', 'SO', 'TF', 'PYTF', 'QZV', 'SCH', 'FTZ',
		],
		userList: [
			{ id: '4d1da981-1294-4dfb-b5fa-7e8bc04f2486', role: 'Geologist', name: 'Lucas Anjos' },
			{ id: '187b5289-4edc-4e76-8417-1274d02cde62', role: 'Geologist', name: 'Ricardo Magno' },
			{ id: 'f0e5d3b2-0ba6-4f05-9ad2-1ef6cca5a8d2', role: 'Geologist', name: 'Alexandre Fraporti' },
			{ id: '87b3e352-3b45-4e4c-b45c-c9bb97346188', role: 'Geologist', name: 'Issacar Barroso' },
			{ id: '8838dc04-fadc-4aa5-ac6e-589d63114804', role: 'Geologist', name: 'Diego Alvarenga' },
			{ id: '73f72ac5-e000-41a0-b9b4-ce04c2592829', role: 'Mining Technician', name: 'Anderson Santos' },
			{ id: '39f5fda1-cf32-4aec-bc43-2140483fbc91', role: 'Mining Technician', name: 'Eleandro Souza' },
			{ id: '6862e35b-2957-4302-a47f-632cd05fc19c', role: 'Mining Technician', name: 'Janiele Santos' },
			{ id: '8aaf6264-aa66-4071-9578-5ff68d59aa42', role: 'Technician Assistant', name: 'Mizael Martínez' }
		]
	}];
