import { NgModule } from '@angular/core';
import { SyncService } from './sync.service';

@NgModule({
	imports: [],
	providers: [SyncService],
	bootstrap: [SyncService],
	exports: [],
})
export class SyncModule { }
