import { Injectable, OnDestroy } from '@angular/core';
import { StorageService } from 'app/core/storage';
import { environment } from 'environments/environment';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { Subscription, forkJoin, from, mergeMap, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { SecurityContextSupplier } from '../supplier';

@Injectable()
export class KeycloakSecurityContextSupplier
	extends SecurityContextSupplier
	implements OnDestroy
{
	private readonly _subscription = new Subscription();

	constructor(
		private readonly keycloakService: KeycloakService,
		private readonly storageService: StorageService
	) {
		super();
		this.initKeycloakEvents();
		this.saveAuthInfo();
	}

	get canLogout(): boolean {
		return true;
	}

	ngOnDestroy(): void {
		this._subscription.unsubscribe();
	}

	isLoggedIn(): Promise<boolean> {
		return this.keycloakService.isLoggedIn();
	}

	login(): Promise<void> {
		return this.keycloakService.login({ prompt: 'login',scope: 'offline_access frontend' });
	}

	logout(): Promise<void> {
		this.storageService.clear();
		return this.keycloakService.logout(environment.auth.logoutUri);
	}

	private initKeycloakEvents(): void {
		this._subscription.add(
			this.keycloakService.keycloakEvents$
				.pipe(
					filter(
						evt => evt.type === KeycloakEventType.OnAuthLogout
					),
					tap(() => this.storageService.clear())
				)
				.subscribe()
		);
	}

	private saveAuthInfo(): void {
		from(this.keycloakService.isLoggedIn())
			.pipe(
				mergeMap((isLoggedIn) => {
					if (isLoggedIn) {
						return forkJoin([
							this.keycloakService.loadUserProfile(),
							this.keycloakService.getToken(),
						]).pipe(
							map(([userInfo, token]) => ({
								userInfo,
								token,
								refreshToken:
									this.keycloakService.getKeycloakInstance()
										.refreshToken,
							})),
							tap(
								authInfo =>
									(this.storageService.authInfo = authInfo)
							)
						);
					}
					this.storageService.clear();
					return of(null);
				})
			)
			.subscribe();
	}


}
