import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, Router, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { FuseConfigModule } from '@fuse/services/config';
import {
	HttpCacheInterceptorModule,
	TTLManager,
	useHttpCacheLocalStorage,
} from '@ngneat/cashew';
import * as Sentry from "@sentry/angular-ivy";
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';
import { MarkdownModule } from 'ngx-markdown';
import { AdapterDate } from './adapters/DateAdapter';
import { AppService } from './app.service';
import { ExplorationTTLManager } from './app.ttlmanager';
import { SitesService } from './modules/admin/apps/sites/sites.service';
import { SyncModule } from './modules/sync/sync.module';

const routerConfig: ExtraOptions = {
	preloadingStrategy: PreloadAllModules,
	scrollPositionRestoration: 'enabled',
};

@NgModule({
	declarations: [AppComponent],
	providers: [
		AppService,
		useHttpCacheLocalStorage,
		{ provide: TTLManager, useClass: ExplorationTTLManager },
		{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
		{ provide: DateAdapter, useClass: AdapterDate },
		// {
		// 	provide: APP_INITIALIZER,
		// 	useFactory: initializeKeycloak,
		// 	multi: true,
		// 	deps: [KeycloakService],
		// },
		useHttpCacheLocalStorage,
		{ provide: TTLManager, useClass: ExplorationTTLManager },
		SitesService,
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false
			})
		},
		{
			provide: Sentry.TraceService,
			deps: [Router]
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true
		}
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule.forRoot(appRoutes, routerConfig),

		// Fuse, FuseConfig & FuseMockAPI
		FuseModule,
		FuseConfigModule.forRoot(appConfig),
		FuseMockApiModule.forRoot(mockApiServices),

		// Core module of your application
		CoreModule,

		// Layout module of your application
		LayoutModule,

		// 3rd party modules that require global configuration via forRoot
		MarkdownModule.forRoot({}),
		SyncModule,
		// KeycloakAngularModule,
		HttpCacheInterceptorModule.forRoot({
			mode: 'cache',
			strategy: 'implicit',
			ttl: 604800000,
		}),
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(_: AppService) {}
}
