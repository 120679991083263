import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { PLUGIN } from './plugin.token';
import { SwUpdateAppPlugin } from './sw-update-app.plugin';

@NgModule({
	imports: [CommonModule],
	providers: [{ provide: PLUGIN, useClass: SwUpdateAppPlugin, multi: true }],
})
export class PwaPluginModule {
	constructor(@Inject(PLUGIN) private plugins: any[]) {}
}
