import { Injectable } from '@angular/core';
import { assign, cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { user as userData } from 'app/mock-api/common/user/data';
import { User } from 'app/core/user/user.types';

@Injectable({
	providedIn: 'root'
})
export class UserMockApi {
	private _user: any;

	/**
	 * Constructor
	 */
	constructor(private _fuseMockApiService: FuseMockApiService) {
		// Register Mock API handlers
		this.registerHandlers();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Register Mock API handlers
	 */
	registerHandlers(): void {
		this._user = JSON.parse(localStorage.getItem('currentUser')); // TODO: ajustar isso para possibilitar o usuário de ser atualizado
		// -----------------------------------------------------------------------------------------------------
		// @ User - GET
		// -----------------------------------------------------------------------------------------------------
		this._fuseMockApiService
			.onGet('api/common/user')
			.reply(() => [200, cloneDeep(this._user)]);

		// -----------------------------------------------------------------------------------------------------
		// @ User - PATCH
		// -----------------------------------------------------------------------------------------------------
		this._fuseMockApiService
			.onPatch('api/common/user')
			.reply(({ request }) => {

				// Get the user mock-api
				const user = cloneDeep(request.body.user);

				// Update the user mock-api
				this._user = user.email ? this._user : JSON.parse(localStorage.getItem('currentUser'));

				// Return the response
				return [200, cloneDeep(this._user)];
			});
	}
}
