/* eslint-disable */
import * as moment from 'moment';

export const boards = [
    {
        id: '2c82225f-2a6c-45d3-b18a-1132712a4234',
        title: 'Apoena',
        description: 'Subsites for Apoena',
        icon: 'heroicons_outline:location-marker',
        lastActivity: moment().startOf('day').subtract(1, 'day').toISOString(),
        members: [
            '9c510cf3-460d-4a8c-b3be-bcc3db578c08',
            'baa88231-0ee6-4028-96d5-7f187e0f4cd5',
            '18bb18f3-ea7d-4465-8913-e8c9adf6f568'
        ]
    },
    {
        id: '0168b519-3dab-4b46-b2ea-0e678e38a583',
        title: 'Matupa',
        description: 'Substies for Matupa',
        icon: 'heroicons_outline:location-marker',
        lastActivity: moment().startOf('day').subtract(2, 'days').toISOString(),
        members: [
            '79ebb9ee-1e57-4706-810c-03edaec8f56d',
            '319ecb5b-f99c-4ee4-81b2-3aeffd1d4735',
            '5bf7ed5b-8b04-46b7-b364-005958b7d82e',
            'd1f612e6-3e3b-481f-a8a9-f917e243b06e',
            'fe0fec0d-002b-406f-87ab-47eb87ba577c',
            '23a47d2c-c6cb-40cc-af87-e946a9df5028',
            '6726643d-e8dc-42fa-83a6-b4ec06921a6b',
            '0d1eb062-13d5-4286-b8d4-e0bea15f3d56'
        ]
    },
    {
        id: 'bc7db965-3c4f-4233-abf5-69bd70c3c175',
        title: 'Aranzanzu',
        description: 'Subsites for Aranzanzu',
        icon: 'heroicons_outline:location-marker',
        lastActivity: moment().startOf('day').subtract(1, 'week').toISOString(),
        members: [
            '6f6a1c34-390b-4b2e-97c8-ff0e0d787839'
        ]
    },
    {
        id: 'bc7db965-3c4f-4233-abf5-69bd70c3c175',
        title: 'Minosa',
        description: 'Subsites for Minosa',
        icon: 'heroicons_outline:location-marker',
        lastActivity: moment().startOf('day').subtract(1, 'week').toISOString(),
        members: [
            '6f6a1c34-390b-4b2e-97c8-ff0e0d787839'
        ]
    },
    {
        id: 'bc7db965-3c4f-4233-abf5-69bd70c3c175',
        title: 'Borborena',
        description: 'Subsites for Borborena',
        icon: 'heroicons_outline:location-marker',
        lastActivity: moment().startOf('day').subtract(1, 'week').toISOString(),
        members: [
            '6f6a1c34-390b-4b2e-97c8-ff0e0d787839'
        ]
    },
    {
        id: 'bc7db965-3c4f-4233-abf5-69bd70c3c175',
        title: 'Tolda Fria',
        description: 'Subsites for Tolda Fria',
        icon: 'heroicons_outline:location-marker',
        lastActivity: moment().startOf('day').subtract(1, 'week').toISOString(),
        members: [
            '6f6a1c34-390b-4b2e-97c8-ff0e0d787839'
        ]
    }
];
export const lists = [
    {
        id: 'a2df7786-519c-485a-a85f-c09a61cc5f37',
        boardId: '2c82225f-2a6c-45d3-b18a-1132712a4234',
        position: 65536,
        title: 'Subsites'
    },
];
export const cards = [
    {
        id: 'e74e66e9-fe0f-441e-a8ce-28ed6eccc48d',
        boardId: '2c82225f-2a6c-45d3-b18a-1132712a4234',
        listId: 'a2df7786-519c-485a-a85f-c09a61cc5f37',
        position: 65536,
        title: 'Collar',
        description: 'Collar',
        labels: [
        ],
    },
    {
        id: 'ed58add1-45a7-41db-887d-3ca7ee7f2719',
        boardId: '2c82225f-2a6c-45d3-b18a-1132712a4234',
        listId: 'a2df7786-519c-485a-a85f-c09a61cc5f37',
        position: 131072,
        title: 'Subsite name',
        labels: [
        ],
        dueDate: null
    },
    {
        id: 'cd6897cb-acfd-4016-8b53-3f66a5b5fc68',
        boardId: '2c82225f-2a6c-45d3-b18a-1132712a4234',
        listId: 'a2df7786-519c-485a-a85f-c09a61cc5f37',
        position: 196608,
        title: 'Subsite name',
        labels: [
        ],
        dueDate: moment().startOf('day').toISOString()
    },
    {
        id: '6da8747f-b474-4c9a-9eba-5ef212285500',
        boardId: '2c82225f-2a6c-45d3-b18a-1132712a4234',
        listId: 'a2df7786-519c-485a-a85f-c09a61cc5f37',
        position: 262144,
        title: 'Subsite name',
        labels: [
        ],
        dueDate: moment().subtract(1, 'day').startOf('day').toISOString()
    },
];
export const labels = [
    {
        id: '40250c45-3c22-480d-a1bc-b1c67f17257d',
        boardId: '2c82225f-2a6c-45d3-b18a-1132712a4234',
        title: 'APP0275'
    },
    {
        id: '51779701-818a-4a53-bc16-137c3bd7a564',
        boardId: '2c82225f-2a6c-45d3-b18a-1132712a4234',
        title: 'Hole2'
    },
    {
        id: 'e8364d69-9595-46ce-a0f9-ce428632a0ac',
        boardId: '2c82225f-2a6c-45d3-b18a-1132712a4234',
        title: 'Hole3'
    },
    {
        id: 'caff9c9b-a198-4564-b1f4-8b3df1d345bb',
        boardId: '2c82225f-2a6c-45d3-b18a-1132712a4234',
        title: 'Hole4'
    },
    {
        id: 'f9eeb436-13a3-4208-a239-0d555960a567',
        boardId: '2c82225f-2a6c-45d3-b18a-1132712a4234',
        title: 'Hole5'
    }
];
