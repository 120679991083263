import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormsService } from 'app/modules/admin/forms/forms.service';
import { SiteType } from 'app/modules/admin/forms/forms.types';

interface NewSiteForm {
	siteName: FormControl<string>;
	hemisferio: FormControl<number>;
	meridiano: FormControl<number>;
}

@Component({
	selector: 'new-site',
	templateUrl: './new-site.component.html',
})
export class NewSiteComponent implements OnInit {
	site: SiteType;
	newSiteForm: FormGroup<NewSiteForm>;

	constructor(
		private readonly formService: FormsService,
		private readonly route: Router,
	) {}

	ngOnInit(): void {
		this.newSiteForm = new FormGroup({
			siteName: new FormControl(undefined, Validators.required),
			hemisferio: new FormControl(undefined),
			meridiano: new FormControl(undefined),
		});
	}

	sendForm() {
		const data = this.newSiteForm.getRawValue();

		this.formService.postEntity('site', data)
			.subscribe(() => {
			this.route.navigate(['/apps/sites']);
		});
	}
}
