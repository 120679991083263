import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { LoginRequiredGuard } from '../../guard';

@Injectable()
export class KeycloakLoginRequiredGuard extends LoginRequiredGuard {
	constructor(private readonly router: Router) {
		super();
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean> {
		return this.router.navigate(['']);
	}
}
