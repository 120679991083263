import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { User } from 'app/core/user/user.types';
import { FormsService } from 'app/modules/admin/forms/forms.service';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject, map } from 'rxjs';
import { SecurityContextSupplier, UserContextSupplier } from '../authorization';

export interface UserToken {
	exp: number;
	iat: number;
	auth_time: number;
	jti: string;
	iss: string;
	aud: string;
	sub: string;
	typ: string;
	azp: string;
	nonce: string;
	session_state: string;
	acr: string;
	'allowed-origins': string[];
	realm_access: RealmAccess;
	resource_access: ResourceAccess;
	scope: string;
	sid: string;
	email_verified: boolean;
	name: string;
	groups: string[];
	preferred_username: string;
	given_name: string;
	family_name: string;
	email: string;
}

export interface RealmAccess {
	roles: string[];
}

export interface ResourceAccess {
	account: RealmAccess;
}

@Injectable({
	providedIn: 'root',
})
export class UserService {
	apiUrl = environment.url;

	private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
	private _usersite: any = '';

	/**
	 * Constructor
	 */
	constructor(
		private readonly httpClient: HttpClient,
		private readonly userSupplier: UserContextSupplier,
		private readonly securityContextSupplier: SecurityContextSupplier,
		// private readonly keycloakService: KeycloakService,
		private readonly appService: AppService,
		private readonly formService: FormsService,
	) {
		this.securityContextSupplier.isLoggedIn();
		this.get().subscribe((user: User) => {
			this.user = user;
		});
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	get site$(): any {
		return this._usersite();
	}

	get user$(): Observable<User> {
		return this._user.asObservable();
	}

	set user(value: User) {
		// Store the value
		this._user.next(value);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Get the current logged in user data
	 */
	get(): Observable<User> {
		return this.userSupplier.profile$();
	}

	/**
	 * Update the user
	 *
	 * @param user
	 */
	update(user: User): Observable<any> {
		return this.httpClient.patch<User>('api/common/user', { user }).pipe(
			map((response) => {
				this._user.next(response);
			}),
		);
	}

	getUserSite(): string {
		const userGroups = this.getObjectToken().groups;

		return this.getUserSitesByGroup(userGroups);
	}

	getAllUserSites(): string[] {
		const userGroups = this.getObjectToken().groups;

		return this.getAllSitesByGroup(userGroups);

	}

	/**
	 * Update the user
	 *
	 * @param user
	 */
	updateUserSite(site: any): Observable<any> {
		return this.httpClient.patch<User>('api/common/user', { site }).pipe(
			map((response) => {
				this._usersite.next(response);
			}),
		);
	}

	getAllUserGroups(): string[] {
		return this.getObjectToken().groups;
	}

	getUserLowestGroup(): string {
		const userGroups = this.getObjectToken().groups;

		const regex = /\/\w{2}\/(\w*)/;
		const result = [];

		userGroups.map((group) => {
			if (regex.test(group)) {
				result.push(group);
			}
		});

		return result[0];
	}

	getAllSitesByGroup(groups: string[]): string[] {
		const regex = /\/\w{2}\/(\w*)/;
		const result = [];

		groups.map((group) => {
			if (regex.test(group)) {
				result.push(group.split('/')[2]);
			}
		});

		return result;
	}


	getUsersOnSameGroup(): any {
		const userGroups = this.getObjectToken().groups;

		const regex = /\/\w{2}\/(\w*)/;
		const result = [];

		let retorno = '';

		userGroups.map((group) => {
			if (regex.test(group)) {
				result.push(group);
			}
		});

		const group = result[0];

		const options = {
			params: {
				name: group,
			},
		};

		this.httpClient.get(this.apiUrl + 'user/group', options).subscribe({
			next: (success: any) => {
				retorno = success;
				return success;
			},
			error: (error: any) => {
				console.error(error.message);
			},
			complete: () => retorno,
		});

		return retorno;
	}

	getUserSitesByGroup(groups: string[]): string {
		const regex = /\/\w{2}\/(\w*)/;
		const result = [];

		groups.map((group) => {
			if (regex.test(group)) {
				result.push(group.split('/')[2]);
			}
		});

		return result[0];
	}

	getObjectToken(): UserToken {
		const result = this.decodeToken(localStorage.getItem('token'));

		return result;
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Métodos que existiam no mock do auth do Fuse
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Is token expired?
	 *
	 * @param token
	 * @param offsetSeconds
	 */
	isTokenExpired(token: string, offsetSeconds?: number): boolean {
		// Return if there is no token
		if (!token || token === '') {
			return true;
		}

		// Get the expiration date
		const date = this.getTokenExpirationDate(token);

		offsetSeconds = offsetSeconds || 0;

		if (date === null) {
			return true;
		}

		// Check if the token is expired
		return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
	}

	/**
	 * Base64 decoder
	 * Credits: https://github.com/atk
	 *
	 * @param str
	 */
	b64decode(str: string): string {
		const chars =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
		let output = '';

		str = String(str).replace(/=+$/, '');

		if (str.length % 4 === 1) {
			throw new Error(
				"'atob' failed: The string to be decoded is not correctly encoded.",
			);
		}

		/* eslint-disable */
		for (
			// initialize result and counters
			let bc = 0, bs: any, buffer: any, idx = 0;
			// get next character
			(buffer = str.charAt(idx++));
			// character found in table? initialize bit storage and add its ascii value;
			~buffer &&
			((bs = bc % 4 ? bs * 64 + buffer : buffer),
			// and if not first of each 4 characters,
			// convert the first 8 bits to one ascii character
			bc++ % 4)
				? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
				: 0
		) {
			// try to find character in table (0-63, not found => -1)
			buffer = chars.indexOf(buffer);
		}
		/* eslint-enable */

		return output;
	}

	/**
	 * Base64 unicode decoder
	 *
	 * @param str
	 */
	b64DecodeUnicode(str: any): string {
		return decodeURIComponent(
			Array.prototype.map
				.call(
					this.b64decode(str),
					(c: any) =>
						'%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2),
				)
				.join(''),
		);
	}

	/**
	 * URL Base 64 decoder
	 *
	 * @param str
	 */
	urlBase64Decode(str: string): string {
		let output = str.replace(/-/g, '+').replace(/_/g, '/');
		switch (output.length % 4) {
			case 0: {
				break;
			}
			case 2: {
				output += '==';
				break;
			}
			case 3: {
				output += '=';
				break;
			}
			default: {
				throw Error('Illegal base64url string!');
			}
		}
		return this.b64DecodeUnicode(output);
	}

	/**
	 * Decode token
	 *
	 * @param token
	 */
	decodeToken(token: string): any {
		// Return if there is no token
		if (!token) {
			return null;
		}

		// Split the token
		const parts = token.split('.');

		if (parts.length !== 3) {
			throw new Error(
				"The inspected token doesn't appear to be a JWT. Check to make sure it has three parts and see https://jwt.io for more.",
			);
		}

		// Decode the token using the Base64 decoder
		const decoded = this.urlBase64Decode(parts[1]);

		if (!decoded) {
			throw new Error('Cannot decode the token.');
		}

		return JSON.parse(decoded);
	}

	/**
	 * Get token expiration date
	 *
	 * @param token
	 */
	getTokenExpirationDate(token: string): Date | null {
		// Get the decoded token
		const decodedToken = this.decodeToken(token);

		// Return if the decodedToken doesn't have an 'exp' field
		if (!decodedToken.hasOwnProperty('exp')) {
			return null;
		}

		// Convert the expiration date
		const date = new Date(0);
		date.setUTCSeconds(decodedToken.exp);

		return date;
	}
}
